import { useSBSState } from "context/global";
import { ArrowRightIcon } from "modules/WebBuilder-V2/shared/icons";
import { Link } from "react-router-dom";

export default function OtherCardTheme7({ item }) {
  const { locale } = useSBSState();

  return (
    <Link to={`/others-service/${item?.product_uuid}`} className="home-page-other-card-link">
      <div className="home-page-other-card">
        <div className="home-page-other-card__image">
          <img src={item?.image} alt="Other Service" />
        </div>

        <div className="home-page-other-card__content">
          <h3 className="home-page-other-card__title mb-2">{item?.name_localized?.[locale] || item?.name_localized.en}</h3>
          <p className="home-page-other-card__description mb-2">
            {item?.description[locale] || item?.description.en}
          </p>
          <div className="home-page-other-card__footer">
            <div className="home-page-other-card__cta">
              <button className="btn btn-primary">
                <ArrowRightIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
