// Traanslation
import Locale from "translations";
// React-Router-Dom
import { Link } from "react-router-dom"
// Assets
import { ReactComponent as ArrowIcon } from 'assets/images/webBuilder/chevron-right.svg'
// --------------------------------------------------------------------------------------------------------
const BreadCrumb = ({ links }) => {
    const { webBuilder } = Locale;
    // ---------- JSX Code --------
    return (
        <div className="bread-crumb-holder">
            <Link to='/' className='link-home'>
                {webBuilder?.home}
                <ArrowIcon className="ml-2" />
            </Link>
            {links?.map((link, index) =>
                <Link
                    key={index}
                    to={link?.url && link?.url}
                    className='link'
                    style={{
                        pointerEvents: !link?.url && 'none'
                    }}
                >
                    <div className='bread-crumb'>
                        {index > 1 && <ArrowIcon />}
                        <span>{link?.name}</span>
                    </div>
                </Link>
            )}
        </div>
    )
}

export default BreadCrumb