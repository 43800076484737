import Carousel from 'react-multi-carousel';
import SectionBG from "assets/images/webBuilder/theme-5-top-bg.svg"
import HotelCardThemeFive from './HotelCard';
import ButtonGroup from 'modules/WebBuilder-V2/shared/CarouselButtonGroup';
import { useSBSState } from 'context/global';
import { useState } from 'react';


export default function HotelTheme5({ id, items, title, body }) {
  const { locale } = useSBSState();
  const [isMoving, setIsMoving] = useState(false);


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      partialVisibilityGutter: 50
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
      partialVisibilityGutter: 50
    },
    tablet: {
      breakpoint: { max: 1200, min: 700 },
      items: 2,
      partialVisibilityGutter: 50
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      partialVisibilityGutter: 50
    },
  };

  const showArrows = items.length > responsive.desktop.items;

  return (
    <section className="wb-hotels" id={id}>
      <div className="abstract-bg" style={{ maskImage: `url(${SectionBG})` }}>
      </div>

      <div className="custom-container position-relative">
        <div className="services-content">

          <h4 className='ervice-header h4 text-white'>{title}</h4>
          <p className="heading_desc text-white pb-3">
            {body}
          </p>

        </div>
        <div className="hotels-cards-container">
          <Carousel
            responsive={responsive}
            slidesToSlide={1}
            keyBoardControl={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            customButtonGroup={showArrows ? <ButtonGroup arrowsBesideDots={false} /> : null}
            renderButtonGroupOutside={true}
            arrows={false}
            infinite={true}
            partialVisbile={true}
            rtl={locale === "ar"}
            beforeChange={() => setIsMoving(true)}
            afterChange={() => setIsMoving(false)}
          >
            {items.map((props, index) => (
              <HotelCardThemeFive key={`${props.product_uuid}-${index}`} isMoving={isMoving} {...props} itemType="hotel" />
            ))}
          </Carousel>


        </div>
      </div>
    </section>
  )
}