import React from "react";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-2.svg";
import { ReactComponent as Hotelcon } from "assets/images/webBuilder/hotel-black.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-black.svg";

import moment, { now } from "moment";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import useExchangeRate from "hooks/useExchangeRate";
import { useSBSState } from "context/global";

function PackageCard(props) {
  const { webBuilder, productsBuilder } = Locale;
  const { locale } = useSBSState();
  const {
    image,
    name,
    name_localized,
    departure_date_at,
    return_date_at,
    stars,
    product_uuid,
    hotelName,
    hotel_name_locale,
    price,
    locationName,
    country,
    initialName,
    currency,
    sourceModule,
    nights_count
  } = props;

  const history = useHistory();
  const trimHotelName = hotelName?.[locale] ? hotelName?.[locale]?.length > 16 ? `${hotelName?.[locale]?.substr(0, 16)}...` : hotelName?.en : "Hotel Name";

  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });
  let countryName = country ? (country?.[locale] || country?.en || "") : (locationName?.[locale] || locationName?.en || "");
  const packageUrl = sourceModule === "service-builder" ? `/package-service/${product_uuid}` : `/package/${product_uuid}`;


  
  return (
    <div className="package-card-container"
      onClick={() => {
        if (product_uuid) {
          history.push({
            pathname: packageUrl,
            state: { productImage: image }
          });
        }
      }}
      key={product_uuid}
    >
      <div className="package-card-details">
        <div className={`package-img ${!image ? "empty-service" : ""}`}>
          {image && <img src={image} alt="our package" />}
          <span className="card-ribbon">{webBuilder.topRate}</span>
          <div className="package-img-data">
            <p className="font-weight-bold">{name_localized?.[locale] || name_localized?.en || initialName || ""}</p>
            {stars > 0 && (
              <div className="starts">
                {[...Array(5)].map((i, index) =>
                  index < stars ? <GoldenStarIcon key={`rate-${index}`} /> : <StarIcon key={`rate-${index}`} />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="package-info">
          {countryName &&
            <div className="location">
              <LocationIcon /> <span className="mr-2">{countryName}</span>
            </div>
          }
          <div className="date">
            <CalendarIcon />{" "}
            {sourceModule === "service-builder" ?
              <span>{nights_count} {productsBuilder.days}</span>
              :
              <span>
                {`${moment(departure_date_at || now()).format(
                  "DD MMM YYYY"
                )} - ${moment(return_date_at || now()).format("DD MMM YYYY")}`}
              </span>
            }
          </div>
          <div className={`hotel mt-1 ${!trimHotelName ? "opacity-0" : ""}`}>
            <Hotelcon /> <span className="mr-2">{trimHotelName || ""}</span>
          </div>
          <div className="hr" />
          {exchangedPrice && 
            <div className="price">
              <span className="me-1">{webBuilder.from}</span>
              <span className="amount">
                {exchangedPrice}
                <span className="currency"> {exchangeCurrency}</span>
              </span>
            </div>
          }
          {+price === 0 &&
            <div className="price">
              <p>{webBuilder.priceOnRequest}</p>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default PackageCard;
