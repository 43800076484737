import React from 'react'
import SelectField from 'components/Form/SelectField/SelectField'
import { LocationMarkerIcon, PackageTypeIcon, SearchIcon } from 'modules/WebBuilder-V2/shared/icons'
import Locale from 'translations'
import SearchService from './SearchService';

export default function SearchBarTheme7({ product_types, countries, preview, searchState, handleSearchInputs, handleSearch, has_marketplace }) {

  const { webBuilder } = Locale;

  if (has_marketplace) {
    return <div className="search-form-web-builder"> <SearchService /></div>;
  }

  return (
    <div className="searchbar_container">
      <div className="form_select">
        <LocationMarkerIcon className="icon" />
        <SelectField
          haslabel={false}
          placeholder={`${webBuilder.selectCountry}`}
          id="search-country"
          name="search-country"
          options={countries}
          value={searchState?.country?.label || searchState?.country?.name}
          onChange={(e) => handleSearchInputs(e, "country")}
        />
      </div>
      <div className="form_select">
        <PackageTypeIcon className="icon" />
        <SelectField
          haslabel={false}
          placeholder={`${webBuilder.packageType}`}
          id="search-product-type"
          name="search-product-type"
          options={product_types}
          value={searchState.proudctType?.label || searchState.proudctType?.name}
          onChange={(e) => handleSearchInputs(e, "proudctType")}
        />
      </div>

      <button className='cta_btn'
        onClick={() => preview && handleSearch()}
      >
        <SearchIcon className="icon" />
        <span>{webBuilder.search}</span>
      </button>
    </div>
  )
}
