import { ReactComponent as GoldenStarIcon } from 'assets/images/webBuilder/golden-star.svg';
import { ReactComponent as StarIcon } from 'assets/images/webBuilder/star.svg';

export default function Rating({ rating }) {
  return (
    <ul className="rating">
      {[...Array(5)].map((_, index) => {
        const ratingValue = index + 1;
        return (
          <li key={index}>
            {ratingValue <= rating ? <GoldenStarIcon /> : <StarIcon />}
          </li>
        );
      })}
    </ul>
  );
}