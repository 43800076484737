import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSBSState } from "context/global";
import { handleSearch } from "modules/WebBuilder-V2/helpers/handleSearch";
import Locale from "translations";
import Carousel from "react-multi-carousel";
import CustomRightArrow from "modules/WebBuilder-V2/shared/CustomRightArrow";
import CustomLeftArrow from "modules/WebBuilder-V2/shared/CustomLeftArrow";
import SearchBarTheme5 from "./MainContent/SearchBarTheme5";

export default function HeroContentTheme5({ hasMarketplace }) {
  const { webBuilder, errors } = Locale;

  const { allCountries, locale } = useSBSState();
  const {
    hero,
    ourServices,
    content,
    has_marketplace,
    pagesData,
    active_modules,
  } = useWebBuilderState();

  const dispatch = useWebBuilderDispatch();
  const [countriesLookup, setCountriesLookup] = useState([]);
  const history = useHistory();
  const preview = true;
  const [searchState, setSearchState] = useState({
    country: null,
    proudctType: null,
  });

  const sliders = hero?.mainContent?.sliders ?? [];

  const visibleServices = ourServices
    ? ourServices?.services
      ?.filter((service) => service?.visible && service?.type !== "packages")
      ?.map((service) => ({
        id: service?.type,
        name: webBuilder[service?.type],
      }))
    : [];

  const product_types = [
    { id: 1, name: webBuilder.hajj },
    { id: 2, name: webBuilder.umrah },
    { id: 3, name: webBuilder.umrahPlus },
    { id: 4, name: webBuilder.tourism },
    { id: 5, name: webBuilder.customServices },
    ...visibleServices,
  ];

  function handleSearchInputs(value, keyName) {
    if (
      keyName === "proudctType" &&
      (value.id === 1 || value.id === 2 || value.id === 3)
    ) {
      setCountriesLookup(() =>
        countriesLookup.filter((country) => country?.id === 966)
      );
    } else {
      setCountriesLookup(allCountries);
    }
    setSearchState({
      ...searchState,
      country:
        keyName === "proudctType" &&
          (value.id === 1 || value.id === 2 || value.id === 3)
          ? allCountries.find((country) => country?.id === 966)
          : searchState.country,
      [keyName]: value,
    });
  }

  function getSearchResults() {
    let searchResults = handleSearch(
      searchState?.country?.id,
      searchState?.proudctType,
      content,
      pagesData
    );
    if (searchResults?.length > 0) {
      dispatch({
        type: "addPackagesResult",
        payload: searchResults,
      });
      history.push(
        `/packages-results?country=${searchState?.country?.id || ""}&type=${searchState?.proudctType?.id}`
      );
    } else {
      store.addNotification({
        title: errors.noDataFound,
        message: errors.noDataMatchSearchCriteria,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
      });
    }
  }
  useEffect(() => {
    if (allCountries) {
      setCountriesLookup(allCountries);
    }
  }, [allCountries]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="wb-hero">
      <div className={`hero-container ${hasMarketplace ? "has-api-integration" : ""}`}>
        <div className="hero-slider-container">
          <Carousel
            responsive={responsive}
            itemClass={"web-builder-slide"}
            slidesToSlide={1}
            keyBoardControl={true}
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            renderButtonGroupOutside={false}
            showDots={false}
            infinite={true}
          >
            {sliders.map((slide) => {
              return (
                <img
                  src={slide.src || slide}
                  key={slide.id || slide}
                  alt=""
                />
              );
            })}
          </Carousel>
        </div>

        <div className="hero-content-container">
          <div className="hero-content">
            <h1 className="header h3">{hero?.mainContent?.title?.[locale]}</h1>
            <p className="body">{hero?.mainContent?.body?.[locale]}</p>
          </div>

          <SearchBarTheme5
            product_types={product_types}
            countries={countriesLookup}
            searchState={searchState}
            handleSearchInputs={handleSearchInputs}
            handleSearch={getSearchResults}
            preview={preview}
            has_marketplace={has_marketplace && active_modules?.length > 0}
          />
        </div>
      </div>
    </div>
  );
}
