
// Translation
import Locale from "translations";
// Assets
import { ReactComponent as StarIcon } from 'assets/images/webBuilder/star.svg';
import { ReactComponent as GoldenStarIcon } from 'assets/images/webBuilder/golden-star.svg';
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-gray.svg";
import { ReactComponent as TagIcon } from "assets/images/webBuilder/tag-gray.svg";
import { ReactComponent as ClockIcon } from "assets/images/webBuilder/clock.svg";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-2.svg";
import ServiceBottomCard from "./ServiceBottomCard";
//--------------------------------------------------------------------------------------


const ServiceViewHeader = (props) => {
  const {
    title,
    stars,
    LocationCountry,
    LocationCity,
    tag,
    time,
    price,
    currency,
    pricePer,
    stayLenght,
  } = props;
  // ---------- JSX Code ----------

  console.log({  price })
  return (
    <div className="service-header">

      <div className="service-info">
        <div className="d-flex flex-column flex-md-row justify-content-md-start align-items-md-center mb-1">
          <div className="d-flex align-items-center justify-content-between title-holder">
            <h2 className="service-title px-md-2">{title}</h2>
            {tag && <span className="d-block d-md-none tag">{tag}</span>}
          </div>
          <div>
            {stars && [...Array(5)].map((i, index) => (
              index < stars ? <GoldenStarIcon key={index} /> : <StarIcon key={index} />
            ))}
          </div>
        </div>
        <div className="icons-holder mb-1">
          {LocationCountry &&
            <span>
              <LocationIcon className="mx-1" />
              {LocationCountry} {LocationCity && `, ${LocationCity}`}
            </span>
          }
          {tag &&
            <span>
              <TagIcon className="mx-1" />
              {tag}
            </span>
          }
          {time &&
            <span>
              <ClockIcon className="mx-1" />
              {time}
            </span>
          }
          {
            stayLenght && (
              <span className="d-flex align-items-center">
                <CalendarIcon className="mx-1" />
                {stayLenght}
              </span>
            )
          }
        </div>
      </div>
      {price ?
        <TotalPrice
          currency={currency}
          price={price}
          pricePer={pricePer}
        />
        : null
      }
      {price ?
        <ServiceBottomCard
        currency={currency}
        price={price}
        stayLenght={stayLenght}
        />
        : null
      }

    </div>
  )
}

export default ServiceViewHeader


export const TotalPrice = ({ currency, price, pricePer }) => {
  const { webBuilder } = Locale
  return (
    <div className="price-holder">
      <p>{webBuilder?.from}</p>
      <p className="price-info">
        {price} {' '}
        <span>{currency}</span>
        {pricePer && <span>/Per{pricePer} </span>}
      </p>
    </div>
  )
}