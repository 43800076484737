import { useState } from "react";
import { useSBSState } from "context/global";
import Locale from "translations";
import { useWebBuilderState } from "context/webBuilder";
import AboutUsThemeSeven from "./Theme7";
import { useLocation } from "react-router-dom";

function AboutUs({ details }) {
  const { locale } = useSBSState();
  const { style, languages } = useWebBuilderState();
  const { items, id, visible } = details;
  const location = useLocation();
  const isAboutusPage = location.pathname.includes("about-us");
  const sectionVisibility = isAboutusPage ? true : (visible || typeof visible === "undefined")
  const firstLanaguageCode = languages[0]?.code;
  const AboutItems = {
    content: AboutContent,
    media: AboutMedia,
  };

  const defaultThemes = ["theme-1", "theme-2", "theme-3", "theme-4", "theme-5", "theme-10",]

  return (
    <>
      {sectionVisibility ?
        <div id={id}>
          {defaultThemes.includes(style.theme) &&
            <div className="web-builder-content-about-us custom-container">
              {items.map((item) => {
                const componentType = item.id.includes("media") ? "media" : "content";
                const Component = AboutItems[componentType];

                return (
                  <Component
                    key={item.id}
                    id={item.id}
                    details={{
                      ...item,
                      head: item?.head?.[locale] || item?.head?.[firstLanaguageCode],
                      body: item?.body?.[locale] || item?.body?.[firstLanaguageCode],
                    }}
                  />
                );
              })}
            </div>
          }
          {style.theme === "theme-7" &&
            <AboutUsThemeSeven items={items} />
          }
        </div>
        :
        null
      }
    </>
  );
}

export default AboutUs;






const AboutContent = ({ details }) => {
  const { webBuilder } = Locale;
  const { head, body, textAlign = "start" } = details;


  let trimBody = body
    ? body?.length > 400
      ? `${body?.substr(0, 400)}...`
      : body
    : "";
  const [isFullContentShown, setIsFullContentShown] = useState(false);

  function toggleContent() {
    setIsFullContentShown(!isFullContentShown);
  }

  return (
    <div className={`about-us-details ${textAlign}`} style={{ textAlign }}>
      <p className="about-us-title">{webBuilder.aboutus}</p>

      <p className="h4"><span>{head}</span></p>

      <p className="text-body mt-3">{!isFullContentShown ? trimBody : body}</p>

      {body?.length > 400 ? (
        <button
          className="btn web-builder-filled-btn mt-3"
          onClick={toggleContent}
        >
          {isFullContentShown
            ? webBuilder.readLessText
            : webBuilder.readMoreText}
        </button>
      ) : null}
    </div>
  );
};

const AboutMedia = ({ details }) => {
  const { mediaUrl, mediaType } = details;

  return (
    <>
      <div
        className={`about-media-container ${mediaType === "image" ? "about-media-image" : ""
          }`}
      >
        {mediaType === "video" ? (
          <iframe
            style={{ maxWidth: "100%" }}
            className="w-100 h-100"
            width="auto"
            height="auto"
            src={`https://www.youtube.com/embed/${mediaUrl}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        ) : (
          <img src={mediaUrl} className="img-fluid" alt="about us" />
        )}
      </div>
    </>
  );
};
