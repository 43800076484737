import { LocationIcon } from "components/Icons";
import { useWebBuilderState } from "context/webBuilder";
import { Profile2Users, ProfileIcon } from "modules/WebBuilder-V2/shared/icons";
import { Link } from "react-router-dom";
import Locale from "translations";

export default function TransferCardTheme2({ item, isSliding }) {
  const { selectedLanguage } = useWebBuilderState();
  const { marketPlace, webBuilder } = Locale;

  const {
    image,
    country,
    name_localized,
    product_uuid,
    withDriver,
    vehicleCapacity,
    trip_type
  } = item;

  const countryName = country ? country[selectedLanguage] || country : "-";

  return (
    <Link
      to={`/transfer-service/${product_uuid}`}
      draggable={false}
      onDragStart={(e) => false}
      onClick={(e) => {
        if (isSliding) {
          e.preventDefault();
        }
      }}
    >
      <div className="home-page-transfer-card">
        <div className="home-page-transfer-card__image">
          <img
            src={image}
            alt="transfer"
            draggable={false}
            onDragStart={(e) => false}
            onMouseDown={(e) => false}
          />
        </div>
        <div className="home-page-transfer-card__content">
        <div className="home-page-transfer-card__header">
          <h5 className="home-page-transfer-card__title">
            {name_localized[selectedLanguage]}
          </h5>
          <span className="home-page-transfer-card__tag">{trip_type}</span>
        </div>
          
          <div className="home-page-transfer-card__body">
            <p className="home-page-transfer-card__location">
              <LocationIcon />
              <span>{countryName}</span>
            </p>
            <p className="home-page-transfer-card__capacity">
              <Profile2Users />
              <span>
                {vehicleCapacity} {marketPlace.adult}
              </span>
            </p>
            <p className="home-page-transfer-card__driver">
              <ProfileIcon />
              <span>
                {withDriver ? webBuilder.withDriver : webBuilder.withoutDriver}
              </span>
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}
