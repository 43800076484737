const ContentCardWrapper = ({ title, children, actionButton }) => {
  // -------- JSX Code ---------
  return (
    <div className="content-wrapper-holder">
      {title && (
        <div className="content-header">
          <h2 className="service-title ">{title}</h2>
          {actionButton && actionButton}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

export default ContentCardWrapper;
