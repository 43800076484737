import React, { useMemo, useState } from "react";
// React-Router-Dom
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// Translation
import Locale from "translations";
// Shared Components
import {
  AdultsField,
  ChildrenField,
  DateField,
  EmailField,
  // NationalityField,
  NoteField,
  UserNameField,
  WhatsAppField,
} from "modules/WebBuilder-V2/shared/RequestForm/InputFields";
import PricingCard from "./PricingCard";
// Helper
import validate, { isFormValid } from "helpers/validate";
// Moment
import moment from "moment";
// Services
import { sendServiceBuilderRequest } from "services/webBuilder";
// React-notification
import { calculateSingleServicePrice } from "./calculateServicePrice";
import { handleResponesNotification } from "helpers/utils";
import { useSBSState } from "context/global";
import PhoneField from "components/Form/PhoneField/PhoneField";
//-----------------------------------------------------------------------------------


export default function TourRequestForm({ data }) {
  // variables
  const { webBuilder } = Locale;
  const { id } = useParams()
  const { allCountries } = useSBSState();

  // states
  const [formState, setFormState] = useState({
    values: {
      date: "",
      user_name: "",
      adults: 1,
      children: 0,
      phone_code: "",
      phone: "",
      whatsapp: "",
      notes: "",
      email: "",
    },
    errors: {},
  });

  const tourPrice = useMemo(() => {
    return calculateSingleServicePrice({
      adultCount: +formState.values.adults || 0,
      adultPrice: +data?.adult_price || 0,
      childrenCount: +formState?.values.children || 0,
      childPrice: +data?.child_price || 0,
      taxesList: data?.taxes
    })
  }, [data?.adult_price, data?.child_price, data?.taxes, formState.values.adults, formState.values.children])



  // *** Logic function
  function checkFormErrors() {
    let submitErrors = {};
    Object.keys(formState?.values).forEach(key => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: key, value: formState['values'][key] },
          { required: true }
        )
      }
    });
    // setFormState(() => submitErrors);
    setFormState({ ...formState, errors: submitErrors });
    return submitErrors;
  }

  async function sendRequet(e) {
    console.log("sendRequet", formState)
    e.preventDefault();
    let formErrors = checkFormErrors();

    if (!isFormValid(formErrors)) return;

    const requestBody = {
      date_from: moment(formState?.values?.date).locale('en').format("YYYY-MM-DD"),
      date_to: moment(formState?.values?.date).locale('en').format("YYYY-MM-DD"),
      adults_count: formState?.values?.adults,
      children_count: formState?.values?.children,
      notes: formState?.values?.notes,
      client: {
        name: formState?.values?.user_name,
        email: formState.values.email,
        phone: formState?.values?.phone,
        whatsapp_number: formState?.values?.whatsapp
      }
    }

    const res = await sendServiceBuilderRequest({ id, serviceType: 'attraction', data: requestBody });
    if (res.status === 200) {
      handleResponesNotification({ alertType: "success", message: res?.data?.message || "Request Sent Successfully" });

      setFormState({
        values: {
          date: "",
          user_name: "",
          adults: 1,
          children: 0,
          phone: "",
          whatsapp: "",
          notes: "",
          email: "",
        },
        errors: {},
      });
    }
  }

  // ----------- JSX Code -----------
  return (
    <form className="request-form" onSubmit={sendRequet}>
      <div className="header">{webBuilder.requestForm}</div>

      <div className="row mx-0">

        <div className="col-12 mt-1">
          <DateField formState={formState} formStateSetter={setFormState} />
        </div>
        <div className="col-12 mt-1">
          <UserNameField formState={formState} formStateSetter={setFormState} />
        </div>

        <div className="col-12 mt-1">
          <EmailField
            formState={formState}
            formStateSetter={setFormState}
          />
        </div>

        <div className="col-12 mt-1">
          <div className="row pb-0">
            <div className="col-md-6 col-12 mt-1 pl-0 pr-0 pr-md-1 pl-md-0">
              <AdultsField
                formState={formState}
                formStateSetter={setFormState}
              />
            </div>
            <div className="col-md-6 col-12 mt-2 mt-md-1 pr-0 pl-0 pl-md-1 pr-md-0">
              <ChildrenField
                formState={formState}
                formStateSetter={setFormState}
              />
            </div>
          </div>
        </div>

        <div className="col-12 mt-2 phone">
          <PhoneField
            countriesLookup={allCountries}
            phoneCode={formState.values?.phone_code}
            phone={formState.values?.phone}
            onSelectPhoneCode={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, phone_code: e },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "phone_code", value: e },
                    { required: true }
                  ),
                },
              });
            }}
            onChangePhone={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, phone: e.target.value },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "phone", value: e.target.value },
                    { required: true }
                  ),
                },
              });
            }}
            phoneCodeColor={
              formState.errors?.phone_code?.required ? "danger" : ""
            }
            phoneCodeError={formState.errors?.phone_code}
            phoneColor={formState.errors?.phone?.required ? "danger" : ""}
            phoneError={formState.errors?.phone}
          />
        </div>
        <div className="col-12 mt-2">
          <WhatsAppField formState={formState} formStateSetter={setFormState} />
        </div>

        <div className="col-12 mt-3">
          <NoteField formState={formState} formStateSetter={setFormState} />
        </div>

        {data?.price ? 
          <div className="col-12">
            <hr className="w-100" />
            <PricingCard
              adultPrice={tourPrice.adultsPrices}
              childPrice={tourPrice.childrenPrices}
              totalPrice={tourPrice.totalPriceAfterTaxes}
              taxes={tourPrice.totalTaxes}
              currency={data?.currency}
              />
          </div>
          :null
        }

        <div className="col-12 mt-3">
          <button type="submit" className="btn submit-btn w-100">
            {webBuilder.sendRequest}
          </button>
        </div>

      </div>
    </form>
  );
}


