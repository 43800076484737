import {
  CheckInField,
  CheckOutField,
  EmailField,
  // NationalityField,
  NoteField,
  UserNameField,
  WhatsAppField,
} from "modules/WebBuilder-V2/shared/RequestForm/InputFields";
import React, { useMemo, useState } from "react";
import Locale from "translations";
import validate, { isFormValid } from "helpers/validate";
import PricingCard from "modules/WebBuilder-V2/shared/RequestForm/PricingCard";
import { calcPackageHotelTotalPrice, generateRoomsTypes } from "modules/WebBuilder-V2/shared/RequestForm/calculateServicePrice";
import { GuestsRoomsInput } from "../Package/PackageRequestForm";
import moment from "moment";
import { sendServiceBuilderRequest } from "services/webBuilder";
import { useParams } from "react-router-dom";
import { handleResponesNotification } from "helpers/utils";
import { isEqual } from "lodash"
import { useSBSState } from "context/global";
import PhoneField from "components/Form/PhoneField/PhoneField";

const initialFormObj = {
  values: {
    check_in: null,
    check_out: null,
    user_name: "",
    email: "",
    // nationality: null,
    phone_code: "",
    phone: "",
    whatsapp: "",
    notes: "",
    rooms: null,
  },
  errors: {},
}

export default function HotelRequestForm({ hotelData }) {
  // hooks
  const { webBuilder, rooms: roomsLocale } = Locale;
  const { id } = useParams();
  const { allCountries } = useSBSState();



  // states
  const [formState, setFormState] = useState(initialFormObj);


  const roomTypesList = useMemo(() => generateRoomsTypes(hotelData, roomsLocale), [hotelData, roomsLocale]);

  const packagePrice = useMemo(() => {
    return calcPackageHotelTotalPrice({
      serviceData: hotelData,
      checkInDate: formState.values.check_in,
      checkOutDate: formState.values.check_out,
      roomsData: formState.values.rooms?.roomsData,
      hasHotels: true
    })
  }, [formState.values.check_in, formState.values.check_out, formState.values.rooms, hotelData]);

  // functions

  function checkFormErrors() {
    let submitErrors = {};
    Object.keys(formState.values).forEach((key) => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: key, value: formState.values[key] },
          { required: key !== "message" }
        ),
      };
    });

    setFormState({
      ...formState,
      errors: submitErrors,
    });
    return submitErrors;
  }

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    let formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) return;

    let requestBody = {
      date_from: formState.values.check_in ? moment(formState.values.check_in).locale("en").format("YYYY-MM-DD") : null,
      date_to: formState.values.check_out ? moment(formState.values.check_out).locale("en").format("YYYY-MM-DD") : null,
      rooms: formState.values.rooms?.roomsData?.map(room=> {
        return {
          room_type: room?.roomType?.room_type,
          adults_count: room?.adultCount,
          children_count: room?.childrenCount,
        }
      }),
      notes: formState.values.notes,
      client: {
        name: formState.values.user_name,
        email: formState.values.email,
        phone: formState.values?.phone_code?.phone_code + formState.values.phone,
        whatsapp_number: formState.values?.phone_code?.phone_code + formState.values.whatsapp,
      }
    }
    let res = await sendServiceBuilderRequest({id, data: requestBody, serviceType: "hotel" })
    if (res?.status === 200) {
      handleResponesNotification({ alertType: "success", message: res?.data?.message || "Request Sent Successfully" });
      setFormState(initialFormObj);
      
    }
  };


  return (
    <form className="request-form" onSubmit={handleSubmit}>
      <div className="header">{webBuilder.requestForm}</div>
      <div className="row mx-0 pb-0">
        <div className="col-6">
          <CheckInField
            formState={formState}
            formStateSetter={setFormState}
          />
        </div>
        <div className="col-6">
          <CheckOutField
            formState={formState}
            formStateSetter={setFormState}
          />
        </div>
        {hotelData?.price && 
          <div className="col-12 mt-1">
            <GuestsRoomsInput
              roomTypes={roomTypesList}
              formState={formState}
              formStateSetter={setFormState}
              isFormSubmited={isEqual(formState, initialFormObj)}
              />
          </div>
        }
        <div className="col-12 mt-1">
          <UserNameField
            formState={formState}
            formStateSetter={setFormState}
          />
        </div>

        {/* email */}
        <div className="col-12 mt-1">
          <EmailField
            formState={formState}
            formStateSetter={setFormState}
          />
        </div>

        {/* <div className="col-12 mt-1">
          <NationalityField
            formState={formState}
            formStateSetter={setFormState}
          />
        </div> */}

        <div className="col-12 mt-2 phone">
          <PhoneField
            countriesLookup={allCountries}
            phoneCode={formState.values?.phone_code}
            phone={formState.values?.phone}
            onSelectPhoneCode={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, phone_code: e },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "phone_code", value: e },
                    { required: true }
                  ),
                },
              });
            }}
            onChangePhone={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, phone: e.target.value },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "phone", value: e.target.value },
                    { required: true }
                  ),
                },
              });
            }}
            phoneCodeColor={
              formState.errors?.phone_code?.required ? "danger" : ""
            }
            phoneCodeError={formState.errors?.phone_code}
            phoneColor={formState.errors?.phone?.required ? "danger" : ""}
            phoneError={formState.errors?.phone}
          />
        </div>

        <div className="col-12 mt-2">
          <WhatsAppField formState={formState} formStateSetter={setFormState} />
        </div>


        <div className="col-12 mt-3">
          <NoteField formState={formState} formStateSetter={setFormState} />
        </div>

        <div className="col-12 mb-1">
          <hr />
        </div>
        {hotelData?.price && 
          <div className="col-12">
            <PricingCard
              totalPrice={packagePrice.totalPriceAfterTaxes}
              adultPrice={packagePrice.adultsPrices}
              childPrice={packagePrice.childrenPrices}
              taxes={packagePrice.totalTaxes}
              currency={packagePrice.totalPriceAfterTaxes ? hotelData?.currency : ""}
              />
          </div>
        }

        <div className="col-12 mt-3">
          <button type="submit" className="btn submit-btn w-100">
            {webBuilder.sendRequest}
          </button>
        </div>
      </div>
    </form>
  );
}