import { useSBSState } from "context/global";
import { Link } from "react-router-dom";
import Locale from "translations";

export default function OtherCardTheme4({ item }) {
  const { locale } = useSBSState();
  const {webBuilder} = Locale;
  
  return (
    <div className="home-page-other-card">
      <div className="home-page-other-card__image">
        <img src={item?.image} alt="Other Service" />
      </div>

      <div className="home-page-other-card__content">
        <h3 className="home-page-other-card__title mb-2">{item?.name_localized?.[locale] || item.name_localized.en}</h3>
        <p className="home-page-other-card__description mb-2">
          {item?.description[locale] || item.description.en}
        </p>
        <div className="home-page-other-card__footer">
          <div className="home-page-other-card__cta">
            <Link to={`/others-service/${item?.product_uuid}`}>
              <button className="btn btn-primary">{webBuilder.view}</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
