import Locale from "translations"

export default function ServiceBottomCard({ currency, price, stayLenght }) {
  const { webBuilder } = Locale
  return (
    <div className="service-bottom-card d-flex d-md-none">
      <div>
        <p className="from">{webBuilder?.from}</p>
        <p className="price-info">
          <span className="price">{price}</span> 
          <span className="currency">{currency} {""}</span>
          {stayLenght && <span className="price-per">/ {stayLenght}</span>}
        </p>
      </div>
      <div>
        <button className="btn btn-primary req-form-btn" onClick={()=> {
          document.querySelector(".request-form")?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
        }}>
          {webBuilder?.requestForm}
        </button>
      </div>
    </div>
  )
}