import moment from 'moment';

export function generateOptionsByCount(count) {
  return Array.from({ length: count }, (_, index) => {
    return {
      id: index + 1,
      name: `${index + 1}`
    }
  });
}

const roomTypesLookup = {
  1: "Single",
  2: "Double",
  3: "Triple",
  4: "Quadruple",
  5: "Quintuple",
  6: "Hexagonal",
  7: "heptathlon",
  8: "suite",
  9: "flat",
};





export function generateRoomsTypes(serviceData, roomsLocale) {
  let roomsData = serviceData?.service?.price.sort((a, b) => a.room_type - b.room_type)?.map((room, index) => {
    return {
      id: `${room?.room_type}-${index}`,
      room_type: room?.room_type,
      name: roomsLocale[`x${room?.room_type}`] || roomTypesLookup[room?.room_type],
      adult_price: room?.adult_price,
      child_price: room?.child_price,
    }
  });
  return roomsData;
}

export function calcPackageHotelTotalPrice({ serviceData, checkInDate, checkOutDate, roomsData = [], hasHotels, adultCount, childrenCount }) {
  let adultsPrices = 0;
  let childrenPrices = 0;
  let totalTaxes = 0;
  let totalPriceAfterTaxes = 0;
  if (!serviceData || !checkInDate || !checkOutDate) {
    return { totalPriceAfterTaxes, totalTaxes, adultsPrices, childrenPrices }
  };

  const pricePerPackage = serviceData?.service?.nights_count > 1;
  const pricePerNight = serviceData?.service?.nights_count === 1;
  const nightsCount = moment(checkOutDate).subtract(1, "day").diff(moment(checkInDate), "days") || 1

  // for package that doesn't have hotels
  if (!hasHotels) {
    const packageAdultPrice = serviceData?.service?.price[0]?.adult_price || 0;
    const packageChildPrice = serviceData?.service?.price[0]?.child_price || 0;
    debugger
    if (pricePerPackage) {
      // Number of Adult
      adultsPrices = adultCount * packageAdultPrice
      // Number of Child 
      childrenPrices = childrenCount * packageChildPrice
    }
    if (pricePerNight) {
      // Number of Adult * Adult(within Room Type) * Night Count.
      adultsPrices = adultCount * packageAdultPrice * nightsCount
      // Number of Child * Child(within Room Type) * Night Count.
      childrenPrices = childrenCount * packageChildPrice * nightsCount
    }
  }

  if (hasHotels && roomsData.length > 0) {
    roomsData?.forEach(room => {
      if (pricePerPackage) {
        // Number of Adult * Adult(within Room Type) 
        adultsPrices = adultsPrices + (room?.adultCount * room?.roomType?.adult_price)
        // Number of Child * Child(within Room Type)
        childrenPrices = childrenPrices + (room?.childrenCount * room?.roomType?.child_price)
      }
      if (pricePerNight) {
        // Number of Adult * Adult(within Room Type) * Night Count.
        adultsPrices = adultsPrices + (room?.adultCount * room?.roomType?.adult_price * nightsCount)
        // Number of Child * Child(within Room Type) * Night Count.
        childrenPrices = childrenPrices + (room?.childrenCount * room?.roomType?.child_price * nightsCount)
      }
    })

    const totalPrice = (adultsPrices || 0) + (childrenPrices || 0);
    // calc taxes
    totalTaxes = calcTotalTaxes({ taxesList: serviceData?.service?.taxes, totalPrice })

    totalPriceAfterTaxes = totalPrice + totalTaxes;
  }

  return { totalPriceAfterTaxes, totalTaxes, adultsPrices, childrenPrices }
}

export function calculateSingleServicePrice({ adultCount, adultPrice, childrenCount, childPrice, taxesList }) {
  let adultsPrices = adultCount * adultPrice;
  let childrenPrices = childrenCount * childPrice;

  let totalPriceBeforeTaxes = (adultsPrices + childrenPrices);

  // total taxes amount
  let totalTaxes = calcTotalTaxes({ taxesList: taxesList, totalPrice: totalPriceBeforeTaxes }) || 0;
  // price after adding taxes amount
  let totalPriceAfterTaxes = totalPriceBeforeTaxes + totalTaxes;

  return { totalPriceAfterTaxes, totalTaxes, adultsPrices, childrenPrices }
}




// calcuate service taxes
function calcTotalTaxes({ taxesList = [], totalPrice }) {
  let totalTaxes = 0;
  taxesList?.forEach(tax => {
    if (tax?.type === "percentage") {
      totalTaxes = totalTaxes + (totalPrice * (tax?.value / 100));
    }
    if (tax?.type === "fixed") {
      totalTaxes = totalTaxes + tax?.value;
    }
  });

  return totalTaxes
}
