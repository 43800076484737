import { useSBSState } from "context/global";
import { MapPin } from "modules/WebBuilder-V2/shared/icons";
import Rating from "modules/WebBuilder-V2/shared/Rating";

export default function AttractionCard({ service }) {
  const { locale } = useSBSState();

  return (
    <>
      <div className="service-card__image">
        <img src={service?.item?.images[0]} alt="attraction" />
      </div>
      <div className="service-card__content">
        <div className="d-flex justify-content-between align-items-center gap-1">
          <h3 className="service-card__title">
            {service?.item?.name?.[locale] || service?.item?.name.en}
          </h3>
          <span className="service-card__tag blue">
            {service?.item?.tour_type.split("_").join(" ")}
          </span>
        </div>
        <div className="service-card__rating">
          <Rating rating={service?.item?.stars || 0} />
          <span className="service-card__tag-rating blue">
            {service?.item?.tour_type.split("_").join(" ")}
          </span>
        </div>
        <div className="service-card__location">
          <MapPin />
          <span>{service?.item?.address?.[locale] || service?.item?.address.en}</span>
        </div>
      </div>
    </>
  );
}
