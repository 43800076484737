import { CarIcon, LocationIcon } from "components/Icons";
import { useWebBuilderState } from "context/webBuilder";
import { Profile2Users } from "modules/WebBuilder-V2/shared/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Locale from "translations";

export default function TransferCardTheme5({ item }) {
  const { selectedLanguage } = useWebBuilderState();
  const { marketPlace, webBuilder } = Locale;

  const {
    image,
    country,
    name_localized,
    product_uuid,
    trip_type,
    withDriver,
    vehicleCapacity,
  } = item;

  const countryName = country ? country[selectedLanguage] || country : "-";

  return (
    <Link to={`/transfer-service/${product_uuid}`}>
      <div className="home-page-transfer-card">
        <div className="home-page-transfer-card__image">
          <img
            src={image}
            alt="transfer"
            draggable={false}
            onDragStart={(e) => false}
            onMouseDown={(e) => false}
          />
        </div>
        <div className="home-page-transfer-card__content">
          <div className="home-page-transfer-card__tag">{trip_type}</div>
          <h5 className="home-page-transfer-card__title">
            {name_localized[selectedLanguage]}
          </h5>
          <div className="home-page-transfer-card__body">
            <p className="home-page-transfer-card__location">
              <LocationIcon />
              <span>{countryName}</span>
            </p>
            <p className="home-page-transfer-card__driver">
              <CarIcon />
              <span>
                {withDriver ? webBuilder.withDriver : webBuilder.withoutDriver}
              </span>
            </p>
            <p className="home-page-transfer-card__capacity">
              <Profile2Users />
              <span>
                {vehicleCapacity} {marketPlace.adult}
              </span>
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}
