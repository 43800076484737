import { useEffect, useState } from "react";
// // React-Router-Dom
import { useParams } from "react-router-dom";
// Translation
import Locale from "translations";
// React-Multi-Carsousel
import Carousel from "react-multi-carousel";
// Shared Components
import BreadCrumb from "modules/WebBuilder-V2/shared/BreadCrumb";
import ContentCardWrapper from "modules/WebBuilder-V2/shared/ContentCardWrapper";
import ServiceViewHeader from "modules/WebBuilder-V2/shared/ServiceViewHeader";
import TourRequestForm from "modules/WebBuilder-V2/shared/RequestForm/AttractionRequestForm";
// import CustomRightArrow from "../../../shared/CustomRightArrow";
// import CustomLeftArrow from "../../../shared/CustomLeftArrow";
// Assets
import placeholderSrc from "assets/images/placeHoleder.png";
import otherImage from "assets/images/webBuilder/attraction.png";
import { ReactComponent as RightTickIcon } from "assets/images/webBuilder/tick-circle.svg";
import { ReactComponent as XTickIcon } from "assets/images/webBuilder/red-x-circle.svg";
// Services
import { getBuilderServicerById } from "services/webBuilder";
// Context
import { useSBSState } from "context/global";
// ---------------------------------------------------------------------------------------
import { isObject, isArray } from "lodash"


const TourServiceView = () => {
  //*** Hooks
  const { webBuilder, productsBuilder } = Locale;
  const { locale, currency: currentCurrency } = useSBSState();
  const { id } = useParams();

  // *** State
  const [tourDetails, setTourDetails] = useState(null);

  const images = tourDetails?.service?.images || [otherImage, otherImage];
  const links = [{ name: tourDetails?.service?.name?.[locale], url: null }];

  const getBuilderServicer = async () => {
    const res = await getBuilderServicerById(id, currentCurrency);
    if (res?.status === 200) {
      setTourDetails(res?.data?.data);
    }
  };

  const hasTerms = tourDetails?.service?.terms && !isArray(tourDetails?.service?.terms) && isObject(tourDetails?.service?.terms)

  useEffect(() => {
    getBuilderServicer();
  }, []);


  let inclusions = []
  let exclusions = []

  if (tourDetails?.service?.inclusion) {
    if (tourDetails?.service?.inclusion?.[locale]?.length > 0) {
      inclusions = tourDetails?.service?.inclusion?.[locale]
    } else if (tourDetails?.service?.inclusion.en) {
      inclusions = tourDetails?.service?.inclusion.en
    } else {
      inclusions = []
    }
  }

  if (tourDetails?.service?.exclusion) {
    if (tourDetails?.service?.exclusion?.[locale]?.length > 0) {
      exclusions = tourDetails?.service?.exclusion?.[locale]
    } else if (tourDetails?.service?.exclusion.en) {
      exclusions = tourDetails?.service?.exclusion.en
    } else {
      exclusions = []
    }
  }

  // --------- JSX Code ----------
  return (
    <div className="service-view-wrapper">
      <BreadCrumb links={links} />
      {tourDetails && (
        <div className="service-view-container w-85 mx-auto pb-5">
          <ServiceViewHeader
            title={tourDetails?.service?.name?.[locale] || tourDetails?.service?.name?.en}
            LocationCountry={
              tourDetails?.service?.country?.names?.[locale] ||
              tourDetails?.service?.country?.names?.en
            }
            LocationCity={tourDetails?.service?.city?.name}
            stars={tourDetails?.service?.stars}
            price={+tourDetails?.price}
            currency={tourDetails?.currency}
          />

          <div className="service-top-section mb-5">
            <div style={{ overflow: "hidden" }}>
              {/* Image Carsoul */}
              <div className="package-carousel">
                <Carousel
                  responsive={{
                    desktop: { breakpoint: { max: 4000, min: 0 }, items: 1 },
                  }}
                  itemClass={"hotel-slide"}
                  className=""
                  slidesToSlide={1}
                  keyBoardControl={true}
                  renderButtonGroupOutside={true}
                  shouldResetAutoplay={true}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  infinite={true}
                >
                  {images?.map((slide, index) => {
                    return (
                      <img
                        src={slide}
                        alt=""
                        key={slide || index}
                        width={"100%"}
                        onError={(e) => {
                          e.target.src = placeholderSrc;
                        }}
                      />
                    );
                  })}
                </Carousel>
              </div>

              {/* Description */}
              {tourDetails?.service?.description && (
                <div className="mt-4">
                  <ContentCardWrapper title={webBuilder?.description}>
                    {/* body of ContentCard */}
                    <p className="card-body-text">
                      {tourDetails?.service?.description[locale] ||
                        tourDetails?.service?.description.en}
                    </p>
                  </ContentCardWrapper>
                </div>
              )}

              {/* Include & Exclude */}
              {(inclusions.length > 0 || exclusions.length > 0) && (
                <div className="mt-4">
                  <ContentCardWrapper title={webBuilder?.includeExeclude}>
                    {/* body of ContentCard */}
                    <div className="d-flex">
                      {inclusions?.length > 0 && (
                        <div className="w-100">
                          {inclusions?.map((item, index) => (
                            <p
                              className="gray-text d-flex"
                              style={{ gap: "4px" }}
                              key={item}
                            >
                              <span style={{ flexShrink: 0 }}>
                                <RightTickIcon />
                              </span>
                              <span>{item}</span>
                            </p>
                          ))}
                        </div>
                      )}

                      {exclusions?.length > 0 && (
                        <div className="w-100">
                          {exclusions?.map((item, index) => (
                            <p
                              className="gray-text d-flex"
                              style={{ gap: "4px" }}
                              key={item}
                            >
                              <span style={{ flexShrink: 0 }}>
                                <XTickIcon />
                              </span>
                              <span>{item}</span>
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  </ContentCardWrapper>
                </div>
              )}

              {/* terms */}
              {hasTerms && (
                <div className="mt-4">
                  <ContentCardWrapper title={productsBuilder.termsAndConditions}>
                    {/* body of ContentCard */}
                    <p className="card-body-text">
                      {tourDetails?.service?.terms?.[locale] || tourDetails?.service?.terms?.en || ""}
                    </p>
                  </ContentCardWrapper>
                </div>
              )}

              {/* Policies */}
              {tourDetails?.service?.policy && (
                <div className="mt-4">
                  <ContentCardWrapper title={webBuilder?.policies}>
                    {/* body of ContentCard */}
                    <p className="card-body-text">
                      {tourDetails?.service?.policy[locale] || tourDetails?.service?.policy.en}
                    </p>
                  </ContentCardWrapper>
                </div>
              )}
            </div>

            {/* TourRequest Form */}
            <div style={{ height: "fit-content" }}>
              <TourRequestForm data={tourDetails} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TourServiceView;
