import React from 'react'
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import Locale from 'translations';
import { useSBSState } from 'context/global';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import useExchangeRate from 'hooks/useExchangeRate';


export default function FlightServiceBuilderCard(props) {
  const { locale } = useSBSState();
  const { webBuilder } = Locale;

  const { product_uuid, image, name, name_localized, destination, price, currency } = props;

  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });
  return (
    <Link to={`/flight-service/${product_uuid}`}>
      <article className="flight-service-builder-card">
        <div className="card-image">
          <img src={image} alt={name_localized ? name_localized[locale] : name} />
        </div>
        <div className="card-details">
          <h5 className="title">
            {name_localized ? name_localized?.[locale] || name_localized?.en : name}
          </h5>
          <p className="location mt-2">
            <LocationIcon />
            <span className="mx-2">
              {destination[locale] || destination.en}
            </span>
          </p>
          {exchangedPrice && 
            <p className="price">
              <span>{webBuilder.from}</span>
              <span className="amount mx-1 truncate-price">{exchangedPrice}</span>
              <span className="currency">{exchangeCurrency}</span>
            </p>
          }
          {+price === 0 &&
            <div className="price mb-1">
              <p>{webBuilder.priceOnRequest}</p>
            </div>
          }
        </div>

      </article>
    </Link>
  )
}
