import TextField from "components/Form/TextField/TextField";
import GlobalIcon from "assets/images/webBuilder/global.svg";
import EditIcon from "assets/images/webBuilder/form-edit-icon.svg";
import SmsIcon from "assets/images/webBuilder/sms.svg";
import MobileIcon from "assets/images/webBuilder/mobile-icon.svg";
import WhatsappIcon from "assets/images/webBuilder/whatsapp.svg";

import { ReactComponent as ProfileIcon } from "assets/images/webBuilder/profile.svg";
import { MinusIcon, PlusIcon } from "components/Icons";
import Locale from "translations";
import validate from "helpers/validate";
import { useWebBuilderState } from "context/webBuilder";
import SelectField from "components/Form/SelectField/SelectField";
import { useState } from "react";
import { ReactComponent as BedIcon } from "assets/images/webBuilder/bed-icon.svg";
import moment from "moment";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import { useSBSState } from "context/global";

const regex = /^[a-zA-Z\u0600-\u06FF\s]+$/; // Regex for English and Arabic characters

export function UserNameField({ formState, formStateSetter }) {
  const { webBuilder } = Locale;
  return (
    <TextField
      haslabel={false}
      id="user_name"
      name="user_name"
      placeholder={webBuilder?.your_name}
      label={webBuilder?.your_name}
      value={formState.values?.user_name}
      onChange={(e) => {
        if (regex.test(e.target.value) || e.target.value === "") {
          formStateSetter({
            ...formState,
            values: { ...formState.values, user_name: e.target.value },
            errors: {
              ...formState.errors,
              ...validate(
                { name: "user_name", value: e.target.value },
                { required: true }
              ),
            },
          });
        }
      }}
      isImage
      image={EditIcon}
      prependimage
      color={formState.errors?.user_name?.required ? "danger" : ""}
      errors={formState.errors?.user_name}
    />
  );
}

export function LastNameField({ formState, formStateSetter }) {
  const { webBuilder } = Locale;

  return (
    <TextField
      haslabel={false}
      id="last_name"
      name="last_name"
      placeholder={webBuilder.lastName}
      label={webBuilder.lastName}
      value={formState.values?.last_name}
      onChange={(e) => {
        if (regex.test(e.target.value) || e.target.value === "") {
          formStateSetter({
            ...formState,
            values: { ...formState.values, last_name: e.target.value },
            errors: {
              ...formState.errors,
              ...validate(
                { name: "last_name", value: e.target.value },
                { required: true }
              ),
            },
          });
        }
      }}
      color={formState.errors?.last_name?.required ? "danger" : ""}
      errors={formState.errors?.last_name}
    />
  );
}

export function AdultsField({ formState, formStateSetter }) {
  const { webBuilder } = Locale;
  const { style } = useWebBuilderState();



  return (
    <div className={`pax-box d-flex justify-content-between ${formState?.errors?.adults?.required || formState?.values?.adults === 0 ? "border-danger" : ""}`}>
      <div className="d-flex align-items-center gap-10">
        <ProfileIcon width="25px" height="25px" />
        <span>{webBuilder.adults}</span>
      </div>
      <div>
        {/* decrease */}
        <button
          type="button"
          className="btn px-0 shadow-none"
          onClick={() =>
            formStateSetter({
              ...formState,
              values: {
                ...formState.values,
                adults: formState.values?.adults === 1 ? null : formState.values?.adults - 1,
              },
              errors: {
                ...formState.errors,
                adults: {
                  ...formState.errors?.adults,
                  required: formState.values?.adults === 1,
                },
              }
            })
          }
          disabled={formState.values?.adults === 1 || formState.values?.adults === null}
        >
          <MinusIcon color={`rgb(${style?.color})`} />
        </button>
        <input
          type="number"
          id="form-adults"
          value={formState.values?.adults || 0}
          color={formState.errors?.adults?.required ? "danger" : ""}
          errors={formState.errors?.adults}
        />
        {/* increase */}
        <button
          type="button"
          className="btn px-0 shadow-none"
          onClick={() =>
            formStateSetter({
              ...formState,
              values: {
                ...formState.values,
                adults: +formState?.values?.adults + 1,
              },
              errors: {
                ...formState.errors,
                adults: {
                  ...formState.errors?.adults,
                  required: false,
                },
              }
            })
          }
        >
          <PlusIcon color={`rgb(${style?.color})`} />
        </button>
      </div>
    </div>
  );
}

export function ChildrenField({ formState, formStateSetter }) {
  const { webBuilder } = Locale;
  const { style } = useWebBuilderState();

  return (
    <div className={`pax-box d-flex justify-content-between`}>
      <div className="d-flex align-items-center gap-10">
        <ProfileIcon width="25px" height="25px" />
        <span>{webBuilder.children}</span>
      </div>
      <div>
        {/* decrease */}
        <button
          type="button"
          className="btn px-0 shadow-none"
          onClick={() =>
            formStateSetter({
              ...formState,
              values: {
                ...formState.values,
                children: formState.values?.children === 1 ? null : formState.values?.children - 1,
              },
              errors: {
                ...formState.errors,
                children: {
                  ...formState.errors?.children,
                  required: false,
                },
              }
            })
          }
          disabled={formState.values?.children === 0 || formState.values?.children === null}
        >
          <MinusIcon color={`rgb(${style?.color})`} />
        </button>
        <input
          type="number"
          id="form-children"
          className=""
          value={formState.values?.children || 0}
          color={formState.errors?.children?.required ? "danger" : ""}
          errors={formState.errors?.children}
        />
        {/* increase */}
        <button
          type="button"
          className="btn px-0 shadow-none"
          onClick={() =>
            formStateSetter({
              ...formState,
              values: {
                ...formState.values,
                children: +formState?.values?.children + 1,
              },
              errors: {
                ...formState.errors,
                children: {
                  ...formState.errors?.children,
                  required: false,
                },
              }
            })
          }
        >
          <PlusIcon color={`rgb(${style?.color})`} />
        </button>
      </div>
    </div>
  );
}

export function NationalityField({ formState, formStateSetter }) {
  const { webBuilder } = Locale;
  const { allCountries } = useSBSState();

  return (
    <SelectField
      haslabel={false}
      id="nationality"
      name="nationality"
      placeholder={webBuilder.nationality}
      label={webBuilder.nationality}
      options={allCountries}
      value={formState.values?.nationality?.name || ""}
      onChange={(e) => {
        formStateSetter({
          ...formState,
          values: {
            ...formState.values,
            nationality: e,
          },
          errors: {
            ...formState.errors,
            ...validate(
              {
                name: "nationality",
                value: e,
              },
              { required: true }
            ),
          },
        });
      }}
      isImage
      image={GlobalIcon}
      prependimage
      color={formState.errors?.nationality?.required ? "danger" : ""}
      errors={formState.errors?.nationality}
    />
  );
}

export function EmailField({ formState, formStateSetter }) {
  const { webBuilder } = Locale;

  return (
    <TextField
      type="email"
      haslabel={false}
      id="form-email"
      name="form-email"
      placeholder={webBuilder.email}
      label={webBuilder.email}
      value={formState.values?.email}
      isImage
      image={SmsIcon}
      prependimage
      onChange={(e) => {
        formStateSetter({
          ...formState,
          values: { ...formState.values, email: e.target.value },
          errors: {
            ...formState.errors,
            ...validate(
              { name: "email", value: e.target.value },
              { required: true, email: true }
            ),
          },
        });
      }}
      color={
        formState.errors?.email?.required || formState.errors?.email?.email
          ? "danger"
          : ""
      }
      errors={formState.errors?.email}
    />
  );
}

export function PhoneField({ formState, formStateSetter }) {
  const { webBuilder } = Locale;
  const phoneCode = localStorage.getItem('page_phone_code')

  return (
    <TextField
      type="number"
      haslabel={false}
      id="form-phone"
      name="form-phone"
      placeholder={webBuilder.phone}
      label={webBuilder.phone}
      value={formState.values?.phone}
      isImage
      image={MobileIcon}
      extraText={formState.values?.nationality?.phone_code || phoneCode || "---"}
      extraTextPosition="prepend"
      prependimage
      onChange={(e) => {
        formStateSetter({
          ...formState,
          values: { ...formState.values, phone: e.target.value },
          errors: {
            ...formState.errors,
            ...validate(
              { name: "phone", value: e.target.value },
              { required: true }
            ),
          },
        });
      }}
      color={formState.errors?.phone?.required ? "danger" : ""}
      errors={formState.errors?.phone}
    />
  );
}

export function WhatsAppField({ formState, formStateSetter }) {
  const { webBuilder } = Locale;
  const phoneCode = localStorage.getItem('page_phone_code')

  return (
    <TextField
      type="number"
      haslabel={false}
      id="form-whatsapp"
      name="form-whatsapp"
      placeholder={webBuilder.whatsapp}
      label={webBuilder.whatsapp}
      value={formState.values?.whatsapp}
      isImage
      image={WhatsappIcon}
      extraText={formState.values?.phone_code?.phone_code || phoneCode || "---"} extraTextPosition="prepend"
      prependimage
      onChange={(e) => {
        formStateSetter({
          ...formState,
          values: { ...formState.values, whatsapp: e.target.value },
          errors: {
            ...formState.errors,
            ...validate(
              { name: "whatsapp", value: e.target.value },
              { required: true }
            ),
          },
        });
      }}
      color={formState.errors?.whatsapp?.required ? "danger" : ""}
      errors={formState.errors?.whatsapp}
    />
  );
}

export function NoteField({ formState, formStateSetter }) {
  const { webBuilder } = Locale;

  return (
    <>
      <textarea
        id="form-notes"
        name="form-notes"
        cols="30"
        rows="3"
        className={`textarea ${formState.errors?.notes?.required && 'border-danger'}`}
        placeholder={webBuilder.note}
        value={formState.values?.notes}
        onChange={(e) =>
          formStateSetter({
            ...formState,
            values: { ...formState.values, notes: e.target.value },
            errors: {
              ...formState.errors,
              ...validate(
                { name: "notes", value: e.target.value },
                { required: true }
              ),
            },
          })
        }
      />

      {formState.errors?.notes?.required && <p className="text-danger">{webBuilder.notesIsRequired}</p>}
  </>
  );
}

export function RoomsTypeInput({ formState, formStateSetter }) {
  function handleSelectRoomsTypesAndCounts(extra_data) {
    if (extra_data?.filter((room) => room?.count && room?.count > 0)) {
      formStateSetter({
        ...formState,
        values: { ...formState.values, extra_data },
        errors: {
          ...formState.errors,
          ...validate(
            { name: "extra_data", value: extra_data },
            { required: true }
          ),
        },
      });
    }
  }

  return (
    <RoomsInput
      roomsTypes={formState.values?.roomsTypes}
      handleSetRooms={handleSelectRoomsTypesAndCounts}
      setRoomsTypes={(roomsTypes) =>
        formStateSetter({
          ...formState,
          values: {
            ...formState.values,
            roomsTypes: roomsTypes,
          },
        })
      }
      finalRoomsData={formState.values?.extra_data || []}
      errors={formState.errors}
    />
  );
}

export function ReservationCategory({ formState, formStateSetter }) {
  const { webBuilder } = Locale;

  return (
    <SelectField
      label={webBuilder.reservationCategory}
      haslabel={false}
      placeholder={webBuilder.reservationCategory}
      value={formState.values?.reservation_category?.value?.name || ""}
      options={formState.values?.reservation_category?.options}
      onChange={(e) => {
        formStateSetter({
          ...formState,
          values: {
            ...formState.values,
            reservation_category: {
              ...formState.values?.reservation_category,
              value: e,
            },
          },
          errors: {
            ...formState.errors,
            ...validate(
              { name: "reservation_category", value: e },
              { required: true }
            ),
          },
        });
      }}
      color={formState.reservation_category?.required ? "danger" : ""}
      errors={formState.reservation_category}
      disabled
    />
  );
}

export function CheckInField({ formState, formStateSetter }) {
  const { marketPlace } = Locale;
  return (
    <DatePickerField
      label={marketPlace.messages.checkIn}
      haslabel={false}
      placeholder={marketPlace.messages.checkIn}
      id="check_in"
      name="check_in"
      date={formState.values?.check_in}
      onChangeDate={(date) => {
        formStateSetter({
          ...formState,
          values: {
            ...formState.values,
            check_in: date,
          },
          errors: {
            ...formState.errors,
            ...validate({ name: "check_in", value: date }, { required: true }),
          },
        });
      }}
      isOutsideRange={(day) =>
        !day.isAfter(moment(new Date()).add(0, "d"), "day")
      }
      color={formState.errors?.check_in?.required ? "danger" : ""}
      errors={formState.errors?.check_in}
    />
  );
}

export function CheckOutField({ formState, formStateSetter }) {
  const { marketPlace } = Locale;
  return (
    <DatePickerField
      label={marketPlace.messages.checkOut}
      haslabel={false}
      placeholder={marketPlace.messages.checkOut}
      id="check_out"
      name="check_out"
      date={formState.values?.check_out}
      onChangeDate={(date) => {
        formStateSetter({
          ...formState,
          values: {
            ...formState.values,
            check_out: date,
          },
          errors: {
            ...formState.errors,
            ...validate({ name: "check_out", value: date }, { required: true }),
          },
        });
      }}
      disabled={!formState.values?.check_in}
      isOutsideRange={(day) =>
        !day.isAfter(moment(formState.values?.check_in), "day")
      }
      color={formState.errors?.check_out?.required ? "danger" : ""}
      errors={formState.errors?.check_out}
    />
  );
}

export function DateField({ formState, formStateSetter }) {
  const { webBuilder } = Locale;
  return (
    <DatePickerField
      haslabel={false}
      label={webBuilder?.date}
      placeholder={webBuilder?.date}
      id="date"
      name="date"
      date={formState.values?.date}
      onChangeDate={(date) => {
        formStateSetter({
          ...formState,
          values: {
            ...formState.values,
            date: date,
          },
          errors: {
            ...formState.errors,
            ...validate({ name: "date", value: date }, { required: true }),
          }
        });
      }}
      isOutsideRange={(day) =>
        !day.isAfter(moment(new Date()).add(0, "d"), "day")
      }
      color={formState.errors?.date?.required ? "danger" : ""}
      errors={formState.errors?.date}
    />
  );
}

function RoomsInput({
  roomsTypes = [],
  setRoomsTypes,
  handleSetRooms,
  finalRoomsData,
  errors,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState();
  const { productsBuilder, productElements } = Locale;
  // const detectClickOutside = useDetectClickOutside({
  //   onTriggered: () => setIsDropdownOpen(false),
  // });
  finalRoomsData = finalRoomsData?.filter((r) => r?.count > 0);

  function handleRoomCount({ roomIndex, value }) {
    let roomTypesClone = [...roomsTypes];
    roomTypesClone[roomIndex] = { ...roomTypesClone[roomIndex], count: value };
    setRoomsTypes(roomTypesClone);
  }

  function toggleDropdown() {
    setIsDropdownOpen(!isDropdownOpen);
  }

  return (
    <div
      className={`rooms-input-container ${errors?.extra_data?.required ? "control-field--danger" : ""
        }`}
    >
      <div
        role="combobox"
        aria-controls="dropdown-list"
        aria-expanded={isDropdownOpen}
        aria-labelledby="dropdown-label"
        onClick={toggleDropdown}
        className="d-flex justify-content-between align-items-center pointer"
      >
        <p className="d-flex align-items-center">
          <BedIcon />

          {finalRoomsData?.length > 0 ? (
            finalRoomsData?.map((room, index) => {
              return (
                <span
                  id="dropdown-label"
                  className="placeholder mx-1"
                  key={room?.id}
                >
                  {room?.count} {room?.name}
                  {index < finalRoomsData?.length - 1 ? "," : ""}
                </span>
              );
            })
          ) : (
            <span id="dropdown-label" className="placeholder mx-2">
              {productsBuilder.RoomType}
            </span>
          )}
        </p>
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.9386 1.7124L8.04859 6.6024C7.47109 7.1799 6.52609 7.1799 5.94859 6.6024L1.05859 1.7124"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      {isDropdownOpen && (
        <div
          role="listbox"
          className={`rooms-dropdown ${errors?.extra_data?.required ? "control-field--danger" : ""
            }`}
          id="dropdown-list"
          aria-expanded={isDropdownOpen}
        >
          <ul>
            {roomsTypes.map((room, index) => {
              return (
                <li
                  key={room?.room_type}
                  className="d-flex align-items-center justify-content-between gap-10"
                >
                  <span> {room?.name} Room</span>
                  <div className="w-50">
                    <SelectField
                      haslabel={false}
                      placeholder={productElements.count}
                      options={room.remaining_availability}
                      value={room?.count?.name}
                      onChange={(e) =>
                        handleRoomCount({ roomIndex: index, value: e })
                      }
                    />
                  </div>
                </li>
              );
            })}
          </ul>

          <hr />
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn submit-rooms-btn"
              aria-label="Select Room Type"
              onClick={() => {
                const formatedRooms = roomsTypes.map((room) => ({
                  id: room?.count?.id,
                  count: room?.count?.name,
                  name: room?.name,
                  type: room?.room_type,
                }));
                handleSetRooms(formatedRooms);
                toggleDropdown();
              }}
            >
              {productsBuilder.submit}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
