import { useSBSState } from "context/global";
import ButtonGroup from "modules/WebBuilder-V2/shared/CarouselButtonGroup";
import Carousel from "react-multi-carousel";

export default function TransferCarousel({ items, Component, isSliding, ...props }) {
  const { locale } = useSBSState();

  const responsive = {
    superLargeDesktop: {
      breakpoint: {
        max: 4000,
        min: 3000,
      },
      items: 3,
    },
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 3,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 567,
      },
      items: 3,
    },
    mobile: {
      breakpoint: {
        max: 567,
        min: 0,
      },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      arrows={false}
      slidesToSlide={1}
      keyBoardControl={true}
      customButtonGroup={<ButtonGroup />}
      renderButtonGroupOutside={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      infinite={true}
      rtl={locale === "ar"}
      {...props}
    >
      {items.map((item, index) => (
        <div className="pe-2" key={item.id || index}>
          <Component item={item} isSliding={isSliding}/>
        </div>
      ))}
    </Carousel>
  );
}