import { useSBSState } from "context/global";

const { default: CustomLeftArrow } = require("./CustomLeftArrow");
const { default: CustomRightArrow } = require("./CustomRightArrow");
let buttonGroupWidth = "100%";

export default function ButtonGroup({ next, previous, goToSlide, ...rest }) {
  const { locale } = useSBSState();
  const isRTL = locale === "ar";
  const { carouselState: { deviceType } } = rest;

  if (rest?.arrowsBesideDots && rest?.carouselState?.domLoaded) {
    const { slidesToShow, totalItems, containerWidth } = rest.carouselState;
    const dotWidth = document.querySelector(".react-multi-carousel-dot")?.getBoundingClientRect().width;
    const arrowsWidth = document.querySelector(".react-multiple-carousel__arrow")?.getBoundingClientRect().width;

    buttonGroupWidth = Math.ceil(totalItems / slidesToShow) * ((dotWidth + (arrowsWidth * 2)) / containerWidth * 100)
  }

  // incase arrows and dots beside each other to prevent arrows and dots to overlay

  return (
    <>
      {deviceType !== "mobile" && deviceType !== "tablet" && (
        <div className="carousel-button-group" style={{ width: buttonGroupWidth < 100 ? `${buttonGroupWidth}%` : "100" }}>
          <CustomLeftArrow onClick={() => isRTL ? next() : previous()} />
          <CustomRightArrow onClick={() => isRTL ? previous() : next()} />
        </div>
      )}
    </>
  );
};
