import Carousel from "react-multi-carousel";
import CustomRightArrow from "./CustomRightArrow";
import CustomLeftArrow from "./CustomLeftArrow";
import placeholderSrc from "assets/images/placeHoleder.png";

export default function ServiceCarousel({ images = [] }) {
  return (
    <>
      <div className="service-carousel">
        <Carousel
          responsive={{
            desktop: { breakpoint: { max: 4000, min: 0 }, items: 1 },
          }}
          itemClass={"hotel-slide"}
          className=""
          slidesToSlide={1}
          keyBoardControl={true}
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
          renderButtonGroupOutside={true}
          shouldResetAutoplay={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          infinite={true}
        >
          {images.map((image, index) => {
            return (
              <img
                src={image}
                alt=""
                key={`${image}-${index}`}
                onError={(e) => {
                  e.target.src = placeholderSrc;
                }}
                onMouseDown={(e) => {
                  return false;
                }}
                draggable={false}
              />
            );
          })}
        </Carousel>
      </div>
    </>
  );
}
