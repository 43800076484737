import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import validate from "helpers/validate";
import PaxSelector from "./PaxSelector";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import Locale from "translations";
import useFlightForm from "./useFlightForm";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const initialValues = {
  from: "",
  to: "",
  departure_date: null,
  return_date: null,
  adults: 1,
  children: 0,
  infants: 0,
  class: null,
  airline: null,
  contact_name: "",
  contact_email: "",
  contact_number: "",
  whatsapp: "",
  note: "",
};
const requiredFields = [
  "from",
  "to",
  "departure_date",
  "return_date",
  "adults",
  "class",
  "airline",
  "contact_name",
  "contact_email",
  "contact_number",
  "whatsapp",
  "note",
];

export default function RoundTripForm({ flightDetails }) {
  // ** hooks
  const { marketPlace, webBuilder } = Locale;
  const {
    formState,
    setFormState,
    airPorts,
    setAirPorts,
    listAirlines,
    setListAirlines,
    getAirPortsList,
    getAirPortsListByCountryId,
    getListAirlines,
    cabinClasses,
    handleSubmit,
  } = useFlightForm({ initialValues, requiredFields, type: "round" });

  const from_port = flightDetails?.service?.trips[0]?.from_port || null;
  const to_port = flightDetails?.service?.trips[0]?.to_port || null;
  const destination = flightDetails?.service?.destination || null;

  useEffect(() => {
    setFormState({
      ...formState,
      values: { ...formState.values, from: from_port, to: to_port },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightDetails]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        {/* from port */}
        <div className="col-12 col-md-4">
          <AutoCompleteField
            haslabel={false}
            labelInner={true}
            isImage={false}
            label={marketPlace.From}
            isSearchable={true}
            listAuto={airPorts.from}
            setListAuto={setAirPorts}
            getListAuto={(e) => getAirPortsList(e, "from")}
            value={formState?.values?.from?.name || ""}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, from: { name: e } },
              });
            }}
            onSelectValue={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, from: e },
                errors: {
                  ...formState.errors,
                  ...validate({ name: "from", value: e }, { required: true }),
                },
              });
            }}
            color={formState.errors?.from?.required ? "danger" : ""}
            errors={formState.errors?.from}
          />
        </div>

        {/* to port */}
        <div className="col-12 col-md-4">
          <AutoCompleteField
            haslabel={false}
            labelInner={true}
            isImage={false}
            label={marketPlace.To}
            isSearchable={true}
            listAuto={airPorts.to}
            setListAuto={setAirPorts}
            getListAuto={(e) => 
              destination
                ? getAirPortsListByCountryId(destination?.country_id, "to")
                : getAirPortsList(e, "to")}
            value={formState?.values?.to?.name || ""}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, to: { name: e } },
              });
            }}
            onSelectValue={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, to: e },
                errors: {
                  ...formState.errors,
                  ...validate({ name: "to", value: e }, { required: true }),
                },
              });
            }}
            color={formState.errors?.to?.required ? "danger" : ""}
            errors={formState.errors?.to}
          />
        </div>

        {/*  departure and return dates */}
        <div className="col-12 col-md-4 p-0">
          <div className="row m-0">
            {/* departure date */}
            <div className="col-6">
              <DatePickerField
                haslabel={false}
                labelInner
                label={marketPlace.departureDate}
                placeholder={webBuilder.date}
                name="departure_date"
                date={formState?.values?.departure_date}
                onChangeDate={(date) => {
                  setFormState({
                    ...formState,
                    values: { ...formState.values, departure_date: date },
                    errors: {
                      ...formState.errors,
                      ...validate(
                        { name: "departure_date", value: date },
                        { required: true }
                      ),
                    },
                  });
                }}
                errors={formState.errors?.departure_date}
                color={
                  formState.errors?.departure_date?.required ? "danger" : ""
                }
              />
            </div>

            {/* return date */}
            <div className="col-6">
              <DatePickerField
                haslabel={false}
                labelInner
                label={marketPlace.returnDate}
                placeholder={webBuilder.date}
                name="return_date"
                date={formState?.values?.return_date}
                onChangeDate={(date) => {
                  setFormState({
                    ...formState,
                    values: { ...formState?.values, return_date: date },
                    errors: {
                      ...formState.errors,
                      ...validate(
                        { name: "return_date", value: date },
                        { required: true }
                      ),
                    },
                  });
                }}
                errors={formState.errors?.return_date}
                color={formState.errors?.return_date?.required ? "danger" : ""}
              />
            </div>
          </div>
        </div>

        {/* pax */}
        <div className="col-12 col-md-4">
          <PaxSelector
            isError={formState.errors?.adults?.required}
            onChange={(adults, children, infants) => {
              setFormState({
                ...formState,
                values: {
                  ...formState?.values,
                  adults: adults === 0 ? null : adults,
                  children: children === 0 ? null : children,
                  infants: infants ? infants : 0,
                },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "adults", value: adults === 0 ? null : adults },
                    { required: true }
                  ),
                  ...validate(
                    {
                      name: "children",
                      value: children === 0 ? null : children,
                    },
                    { required: true }
                  ),
                },
              });
            }}
          />
        </div>

        {/* class option */}
        <div className="col-12 col-md-4">
          <SelectField
            haslabel={false}
            labelInner
            label={marketPlace.class}
            placeholder={webBuilder.select}
            name="class"
            options={cabinClasses}
            value={formState?.values?.class?.name}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, class: e },
                errors: {
                  ...formState.errors,
                  ...validate({ name: "class", value: e }, { required: true }),
                },
              });
            }}
            errors={formState.errors?.class}
            color={formState.errors?.class?.required ? "danger" : ""}
          />
        </div>

        {/* airline */}
        <div className="col-12 col-md-4">
          <AutoCompleteField
            haslabel={false}
            labelInner={true}
            isImage={false}
            label={marketPlace.airlines}
            isSearchable={true}
            listAuto={listAirlines}
            setListAuto={setListAirlines}
            getListAuto={getListAirlines}
            value={formState?.values?.airline?.name || ""}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, airline: { name: e } },
              });
            }}
            onSelectValue={(e) => {
              setFormState({
                ...formState,
                values: { ...formState?.values, airline: e },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "airline", value: e },
                    { required: true }
                  ),
                },
              });
            }}
            errors={formState.errors?.airline}
            color={formState.errors?.airline?.required ? "danger" : ""}
          />
        </div>

        {/* contact name */}
        <div className="col-12 col-md-3">
          <TextField
            type="text"
            haslabel={false}
            labelInner
            label={webBuilder.contactName}
            placeholder={webBuilder.enteryourName}
            name="contact_name"
            value={formState?.values?.contact_name}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, contact_name: e.target.value },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "contact_name", value: e.target.value },
                    { required: true }
                  ),
                },
              });
            }}
            errors={formState.errors?.contact_name}
            color={formState.errors?.contact_name?.required ? "danger" : ""}
          />
        </div>

        {/* contact Email */}
        <div className="col-12 col-md-3">
          <TextField
            type="email"
            haslabel={false}
            labelInner
            label={webBuilder.contactEmail}
            placeholder={webBuilder.enteryourEmail}
            name="contact_email"
            value={formState?.values?.contact_email}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, contact_email: e.target.value },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "contact_email", value: e.target.value },
                    { required: true, email: true }
                  ),
                },
              });
            }}
            errors={formState.errors?.contact_email}
            color={
              formState.errors?.contact_email?.required ||
              formState.errors?.contact_email?.email
                ? "danger"
                : ""
            }
          />
        </div>

        {/* contact phone number */}
        <div className="col-12 col-md-3">
          <TextField
            type="number"
            haslabel={false}
            labelInner
            label={webBuilder.contactNumber}
            placeholder={webBuilder.enteryourNumber}
            name="contact_number"
            value={formState?.values?.contact_number}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, contact_number: e.target.value },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "contact_number", value: e.target.value },
                    { required: true }
                  ),
                },
              });
            }}
            errors={formState.errors?.contact_number}
            color={formState.errors?.contact_number?.required ? "danger" : ""}
          />
        </div>

        {/* whatsapp */}
        <div className="col-12 col-md-3">
          <TextField
            type="number"
            haslabel={false}
            labelInner
            label={webBuilder.whatsApp}
            placeholder={webBuilder.enteryourNumber}
            name="whatsapp"
            value={formState?.values?.whatsapp}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, whatsapp: e.target.value },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "whatsapp", value: e.target.value },
                    { required: true }
                  ),
                },
              });
            }}
            errors={formState.errors?.whatsapp}
            color={formState.errors?.whatsapp?.required ? "danger" : ""}
          />
        </div>

        {/* notes */}
        <div className="col-12 textarea-col">
          <TextField
            haslabel={false}
            labelInner
            label={webBuilder.note}
            placeholder={webBuilder.enteryourNote}
            name="note"
            value={formState?.values?.note}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, note: e.target.value },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "note", value: e.target.value },
                    { required: true }
                  ),
                },
              });
            }}
            errors={formState.errors?.note}
            color={formState.errors?.note?.required ? "danger" : ""}
          />
        </div>

        <div className="col-12">
          <button
            type="submit"
            className="submit-button btn btn-primary mt-1 w-100 p-2"
          >
            {webBuilder.requestTicket}
          </button>
        </div>
      </div>
    </form>
  );
}
