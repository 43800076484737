import { useWebBuilderState } from "context/webBuilder";
import OtherTheme1View from "./theme1";
import OtherTheme2View from "./theme2";
import OtherTheme4View from "./theme4";
import OtherTheme5View from "./theme5";
import OtherTheme7View from "./theme7";
import OtherTheme10View from "./theme10";

export default function OtherServiceSection({ details }) {
  const {
    style: { theme },
  } = useWebBuilderState();

  const renderTransferView = () => {
    return {
      "theme-1": <OtherTheme1View details={details} />,
      "theme-2": <OtherTheme2View details={details} />,
      "theme-4": <OtherTheme4View details={details} />,
      "theme-5": <OtherTheme5View details={details} />,
      "theme-7": <OtherTheme7View details={details} />,
      "theme-10": <OtherTheme10View details={details}/>,
    };
  };

  return renderTransferView()[theme] || <OtherTheme1View details={details} />;
}
