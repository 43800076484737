import { useSBSState } from "context/global";
import Carousel from "react-multi-carousel";
const { default: CustomLeftArrow } = require("./CustomLeftArrow");
const { default: CustomRightArrow } = require("./CustomRightArrow");


export default function ServiceSlider({items = [], slideItem: Item, render, ...props}) {
  const { locale } = useSBSState();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1,
      partialVisibilityGutter: 50
    },
  };

  return (
    <Carousel
      responsive={responsive}
      itemClass={"service-slide"}
      slidesToSlide={1}
      arrows={false}
      keyBoardControl={true}
      customButtonGroup={<ButtonGroup items={items} />}
      renderButtonGroupOutside={true}
      renderArrowsWhenDisabled={true}
      infinite={true}
      partialVisbile={true}
      rtl={locale === "ar"}
      {...props}
    >
      {items.map((item) => {
        return render(item);
      })}
    </Carousel>
  );

}




let buttonGroupWidth = "100%";

function ButtonGroup({ next, previous, goToSlide, ...rest }) {
  const { locale } = useSBSState();
  const isRTL = locale === "ar";
  const { carouselState: { slidesToShow } } = rest;

  if (rest?.arrowsBesideDots && rest?.carouselState?.domLoaded) {
    const { slidesToShow, totalItems, containerWidth } = rest.carouselState;
    const dotWidth = document.querySelector(".react-multi-carousel-dot")?.getBoundingClientRect().width;
    const arrowsWidth = document.querySelector(".react-multiple-carousel__arrow")?.getBoundingClientRect().width;

    buttonGroupWidth = Math.ceil(totalItems / slidesToShow) * ((dotWidth + (arrowsWidth * 2)) / containerWidth * 100)
  }

  // incase arrows and dots beside each other to prevent arrows and dots to overlay

  return (
    <>
      {rest.items.length >= slidesToShow && (
        <div className="carousel-button-group" style={{ width: buttonGroupWidth < 100 ? `${buttonGroupWidth}%` : "100" }}>
          <CustomLeftArrow onClick={() => isRTL ? next() : previous()} />
          <CustomRightArrow onClick={() => isRTL ? previous() : next()} />
        </div>
      )}
    </>
  );
};
