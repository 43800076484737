import React from "react";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-gray.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as MoonIcon } from "assets/images/webBuilder/moon.svg";
import { ReactComponent as RouteIcon } from "assets/images/webBuilder/routing-2.svg";
import { ReactComponent as HotelIcon } from "assets/images/webBuilder/hotel.svg";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Locale from "translations";
import { calcExchangeRatePrice } from "helpers/utils";
import { useSBSState } from "context/global";
import FlightServiceCardTheme4 from "modules/WebBuilder-V2/Components/Content/Flights/Theme4Card";
import moment from "moment";
import FlightServiceBuilderCard from "modules/WebBuilder-V2/Components/Content/Flights/ServiceBuilderCard";

export default function ResultItem({ item, productType }) {
  const { webBuilder } = Locale;
  const { currency: currentCurrency, exchangeRates, locale } = useSBSState();



  const trimName = item?.name
    ? item?.name?.length > 25
      ? `${item?.name?.substr(0, 25)}...`
      : item?.name
    : "";
  const nightsCount = item?.items?.find((item) => item?.item.id === 6)?.itemable
    ?.night_count;
  const hotelName = item?.hotelName?.[locale] || item?.hotelName?.en;
  
  const trimHotelName = hotelName
    ? hotelName?.length > 25
      ? `${hotelName?.substr(0, 25)}...`
      : hotelName
    : "";

  const serviceName = productType.id === 1 || productType.id === 2 || productType.id === 3 || productType?.id === 4 || productType?.id === 5 ? "package" : productType?.id
  // const link = `/${serviceName}/${item?.product_uuid}`;
  const link = item?.sourceModule === "service-builder" ? `/${serviceName}-service/${item?.product_uuid}` : `/package/${item?.product_uuid}`;

  let exchageRate = exchangeRates?.find(rate => rate?.from === item.currency)?.rate;
  let exchagedPrice = calcExchangeRatePrice(exchageRate, +item?.price)
  let countryName = item?.country ? (item?.country?.[locale] || item?.country) : (item?.locationName?.[locale] || item?.locationName);

  return (
    <>
      {productType.id === "flights" ?
        item?.sourceModule === "service-builder"
          ? <FlightServiceBuilderCard {...item} />
          : <FlightServiceCardTheme4 {...item} />
        :
        <div className="result-item">
          <img src={item?.image} alt="" />
          <div className="item-details">
            {countryName &&
              <div className="location">
                <LocationIcon /> <span>{countryName}</span>
              </div>
            }
            <div className="name">
              <h4 title={item?.name}>{trimName}</h4>
              {item?.stars > 0 && (
                <div className="start">
                  {[...Array(5)].map((i, index) =>
                    index < (item.product_classification?.id || item?.stars) ? (
                      <GoldenStarIcon key={`stars-${index}`} />
                    ) : (
                      <StarIcon key={`stars-${index}`} />
                    )
                  )}
                </div>
              )}
            </div>
            {item?.hotelName ? (
              <div className="hotel-name my-2">
                <HotelIcon />
                <h5 title={hotelName}>{trimHotelName}</h5>
              </div>
            ) : null}
            <div className="route">
              <div>
                <RouteIcon />
                <p className="mx-2">{productType?.name || webBuilder[item?.product_type?.name] || item?.product_type?.name}</p>
              </div>

              {nightsCount && (
                <div>
                  <MoonIcon />

                  <p>
                    {/* {
                      item?.items?.find((item) => item?.item.id === 6)?.itemable
                        ?.night_count
                    } */}
                    {" "}
                    {moment(moment(item?.return_date_at)).diff(moment(item?.departure_date_at), "days")}
                    {" "}
                    {webBuilder.night}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="price">
            <div>
              <span> {webBuilder.startFrom}</span>
              <p>
                {exchagedPrice} <span>{currentCurrency}</span>
              </p>
            </div>
            <button className="btn">
              <Link to={link}>{webBuilder.viewMore}</Link>
            </button>
          </div>
        </div>
      }
    </>
  );
}
