import TransferCardTheme10 from "./TransferCardTheme10";

export default function TransferTheme10View({ details }) {
  const { items, title, body } = details;

  return (
    <div className="home-page-transfer-section">
      <div className="web-builder-content custom-container">
        <div className="home-page-transfer-section__content">
          <div className="d-flex flex-column align-items-center mb-4">
            <h2 className="home-page-transfer-section__title">{title}</h2>
            <p className="home-page-transfer-section__description">{body}</p>
          </div>
          <div className="home-page-transfer-section__cards-list">
            {items.map((item) => (
              <TransferCardTheme10 item={item} key={item.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
