// Translation
import Locale from "translations";
// Assets
import { ReactComponent as ProfileIcon } from "assets/images/webBuilder/profile.svg";

//--------------------------------------------------------------------------------

export default function PricingCard({ totalPrice, adultPrice, childPrice, taxes, currency }) {
  const { webBuilder } = Locale;

  // -------- JSX Code ---------
  return (
    <div className="prices">
      {/* adult */}

      <div className="d-flex justify-content-between align-items-center">
        <span className="d-flex align-items-center">
          <ProfileIcon />
          {webBuilder.adult}
        </span>
        <span className="total-price">{adultPrice} {currency}</span>
      </div>
      <hr className="my-3" />

      {/* child */}
      <div className="d-flex justify-content-between align-items-center">
        <span className="d-flex align-items-center">
          <ProfileIcon />
          {webBuilder.child}
        </span>
        <span className="total-price">{childPrice} {currency}</span>
      </div>
      <hr className="my-3" />

      {/* tax */}
      <div className="d-flex justify-content-between align-items-center">
        <span className="d-flex align-items-center mb-2">{webBuilder.tax}</span>
        <span style={{color: "#000000"}}>{taxes} {currency}</span>
      </div>
      <hr className="my-3" />

      {/* total */}
      <div className="d-flex justify-content-between align-items-center">
        <span className="d-flex align-items-center">{webBuilder.totalPrice}</span>
        <span className="total-price">{totalPrice} {currency}</span>
      </div>
    </div>
  );
}
