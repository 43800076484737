import React, { useEffect, useState } from "react";

export default function ServiceTabBar({ tabs, scrollOffsetMargin = 70 }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeElementWidth, setActiveElementWidth] = useState(100);
  const [activeElementLeft, setActiveElementLeft] = useState(0);
  const [barWidth, setBarWidth] = useState("100%");
  const [direction, setDirection] = useState("ltr"); // Internal state for direction

  const updateBarWidth = () => {
    const isScroll =
      document.querySelector(".service-tabBar-tabs").scrollWidth >
      document.querySelector(".service-tabBar-tabs").clientWidth;

    if (isScroll) {
      setBarWidth(document.querySelector(".service-tabBar").scrollWidth + "px");
    } else {
      setBarWidth("100%");
    }
  };

  const handleSelectTab = (index, element) => {
    const currentTab = tabs[index];
    setActiveTabIndex(index);
    setActiveElementWidth(element.offsetWidth);

    // Calculate activeElementLeft based on the direction
    if (direction === "ltr") {
      setActiveElementLeft(element.offsetLeft);
    } else {
      const containerWidth =
        document.querySelector(".service-tabBar-tabs").clientWidth;
      setActiveElementLeft(containerWidth - element.offsetLeft - element.offsetWidth);
    }

    const ele = document.querySelector(
      `.service-view-wrapper ${currentTab.hash}`
    );
    if (ele) {
      window.scrollTo({
        top: ele.offsetTop - scrollOffsetMargin,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    // Detect and set the direction dynamically
    const detectedDirection = document.dir || "ltr";
    setDirection(detectedDirection);

    updateBarWidth();
    window.addEventListener("resize", updateBarWidth);

    return () => {
      window.removeEventListener("resize", updateBarWidth);
    };
  }, [tabs]);

  useEffect(() => {
    const element = document.querySelector(".service-tabBar-tabs li");
    if (element) {
      setActiveElementWidth(element.offsetWidth);

      // Initial calculation for activeElementLeft based on the direction
      if (direction === "ltr") {
        setActiveElementLeft(element.offsetLeft);
      } else {
        const containerWidth =
          document.querySelector(".service-tabBar-tabs").clientWidth;
        setActiveElementLeft(
          containerWidth - element.offsetLeft - element.offsetWidth
        );
      }
    }
  }, [direction]);

  return (
    <div className="service-tabBar">
      <ul
        className="service-tabBar-tabs"
        style={{ direction }} // Use the internal state for direction
      >
        {tabs?.map((tab, index) => (
          <li
            key={tab.name + "-" + index}
            className={`tab ${activeTabIndex === index ? "active-tab" : ""}`}
            onClick={(e) => handleSelectTab(index, e.currentTarget)}
            role="button"
            tabIndex={0}
          >
            <span>{tab.icon}</span>
            <span>{tab.name}</span>
          </li>
        ))}
      </ul>

      <div className="bar" style={{ width: barWidth }}>
        <div
          className="active-bar"
          style={{
            width: `${activeElementWidth}px`,
            insetInlineStart: `${activeElementLeft}px`, // Works for both RTL and LTR
          }}
        />
      </div>
    </div>
  );
}
