import { useWebBuilderState } from 'context/webBuilder';
// import TransferCardTeme1 from 'modules/WebBuilder-V2/Components/Content/Transfer/theme1/TransferCardTheme1';
import TransferCardTheme10 from 'modules/WebBuilder-V2/Components/Content/Transfer/theme10/TransferCardTheme10';
import TransferCardTheme2 from 'modules/WebBuilder-V2/Components/Content/Transfer/theme2/TransferCardTheme2';
import TransferCardTheme4 from 'modules/WebBuilder-V2/Components/Content/Transfer/theme4/TransferCardTheme4';
import TransferCardTheme5 from 'modules/WebBuilder-V2/Components/Content/Transfer/theme5/TransferCardTheme5';
import TransferCardTheme7 from 'modules/WebBuilder-V2/Components/Content/Transfer/theme7/TransferCardTheme7';

export default function CustomPageTransfers({ details, title }) {
  const { style: { theme } } = useWebBuilderState();
  const { id, items } = details;



  const renderTransferView = (item) => {
    return {
      "theme-1": <TransferCardTheme5 item={item} itemType="transfer" />,
      "theme-2": <TransferCardTheme2 item={item} itemType="transfer" />,
      "theme-4": <TransferCardTheme4 item={item} itemType="transfer" />,
      "theme-5": <TransferCardTheme5 item={item} itemType="transfer" />,
      "theme-7": <TransferCardTheme7 item={item} itemType="transfer" />,
      "theme-10": <TransferCardTheme10 item={item} itemType="transfer" />,
    };
  };

  return (
    <>
      <h3 className="section-title mb-3 font-weight-bold">{title}</h3>
      <div className="home-page-transfer-section p-0" id={id} style={{ backgroundColor: "transparent" }}>
        <div className={`${theme === "theme-1" ? "d-flex gap-10 " : "home-page-transfer-section__cards-list"}`}>
          {items.map((item,) => (
            <>
              {renderTransferView(item)[theme]}
            </>
          ))}

          {/* {items?.length > 0 && items?.map(item => {
            return (

            )
          })} */}
        </div>
      </div>
    </>
  )
}
