import { CarIcon, LocationIcon } from "components/Icons";
import { useWebBuilderState } from "context/webBuilder";
import { Profile2Users } from "modules/WebBuilder-V2/shared/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Locale from "translations";

export default function TransferCardTheme7({ item }) {
  const { selectedLanguage } = useWebBuilderState();
  const { marketPlace, webBuilder } = Locale;

  const {
    image,
    country,
    name_localized,
    product_uuid,
    withDriver,
    trip_type,
    vehicleCapacity,
  } = item;

  const countryName = country ? country[selectedLanguage] || country : "-";

  return (
    <Link to={`/transfer-service/${product_uuid}`}>
      <div className="home-page-transfer-card">
        <div className="home-page-transfer-card__image">
          <img src={image} alt="Transfer" />
        </div>
        <div className="home-page-transfer-card__content">
          <div className="home-page-transfer-card__details">
            <h3 className="home-page-transfer-card__title">
              {name_localized[selectedLanguage]}
            </h3>
            <span className="home-page-transfer-card__tag">{trip_type}</span>
          </div>
          <div className="home-page-transfer-card__body">
            <p className="home-page-transfer-card__location">
              <LocationIcon />
              <span>{countryName}</span>
            </p>

            <div className="d-flex justify-content-between">
              <p className="home-page-transfer-card__driver flex-grow-1">
                <CarIcon />
                <span>
                  {withDriver
                    ? webBuilder.withDriver
                    : webBuilder.withoutDriver}
                </span>
              </p>
              <p className="home-page-transfer-card__capacity flex-grow-1">
                <Profile2Users />
                <span>
                  {vehicleCapacity} {marketPlace.adult}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
