import { useWebBuilderState } from "context/webBuilder";
import FlightServiceCardTheme4 from "modules/WebBuilder-V2/Components/Content/Flights/Theme4Card";
import FlightServiceCardTheme5 from "modules/WebBuilder-V2/Components/Content/Flights/Theme5Card";
import FlightServiceBuilderCard from "modules/WebBuilder-V2/Components/Content/Flights/ServiceBuilderCard";
import { useSBSState } from "context/global";
import ButtonGroup from "modules/WebBuilder-V2/shared/CarouselButtonGroup";
import Carousel from "react-multi-carousel";

export default function CustomPageFlights({ details, title }) {
  const { locale } = useSBSState();
  const { style } = useWebBuilderState();
  const { id, items, items_per_row = 2 } = details;
  console.log(details);
  

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1,
    },
  };

  const showArrows =
    items?.filter((item) => item?.product_uuid).length >
    responsive.desktop.items;

  function renderFlightView(item) {
    switch (style.theme) {
      case "theme-1":
      case "theme-2":
      case "theme-4":
      case "theme-10":
        return <FlightServiceCardTheme4 {...item} itemType="flight" />;
      case "theme-5":
      case "theme-7":
        return <FlightServiceCardTheme5 {...item} itemType="flight" />;
      default:
        return <FlightServiceCardTheme4 {...item} itemType="flight" />;
    }
  }

  return (
    <>
      <h3 className="section-title mb-3 font-weight-bold">{title}</h3>
      <div id={id} className="web-builder-content-flights py-0">
        {items?.[0]?.sourceModule === "service-builder" ? (
          // service builder cards
          <div className="services-carousel-container">
            <Carousel
              responsive={responsive}
              itemClass={"service-slide"}
              arrows={false}
              slidesToSlide={1}
              keyBoardControl={true}
              customButtonGroup={showArrows ? <ButtonGroup /> : null}
              renderButtonGroupOutside={true}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              infinite={true}
              rtl={locale === "ar"}
            >
              {items.map((item, index) => (
                <div className="pe-2" key={item.id || index}>
                  <FlightServiceBuilderCard {...item} itemType="flight" />
                </div>
              ))}
            </Carousel>
          </div>
        ) : (
          // package builder cards
          <div
            className={"flights-cards-container"}
            style={{ gridTemplateColumns: `repeat(${+items_per_row} , 1fr)` }}
          >
            {items.map((item, index) => (
              <div>{renderFlightView(item)}</div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
