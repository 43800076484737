import { useSBSState } from "context/global";
import { handleResponesNotification } from "helpers/utils";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { fetchTransporters } from "services/lookups";
import { fetchAirPorts, fetchAirPortsByCountryId } from "services/marketplace";
import { sendServiceBuilderRequest } from "services/webBuilder";

const cabinClasses = [
  { id: "Economy", name: "Economy" },
  { id: "PremiumEconomy", name: "Premium Economy" },
  { id: "Business", name: "Business" },
  { id: "First", name: "First" },
];

export default function useFlightForm({ initialValues, requiredFields, type }) {
  // *** hooks
  const { locale } = useSBSState();
  const { id } = useParams();

  // ***** states
  const [formState, setFormState] = useState({
    values: initialValues,
    errors: {},
  });
  const [airPorts, setAirPorts] = useState({
    from: [],
    to: [],
  });
  const [listAirlines, setListAirlines] = useState([]);

  // variables
  const isMultiDestination = type === "multi-destination";

  // **************************************** functions **************************************** //

  // check flight form errors
  function checkFormErrors() {
    let submitErrors = {};
    requiredFields.forEach((key) => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: key, value: formState.values[key] },
          { required: true, email: key.toString().includes("email") }
        ),
      };
    });

    // for multi destination form
    if (isMultiDestination) {
      formState.values.destinations.forEach((destination, index) => {
        Object.keys(destination).forEach((key) => {
          submitErrors = {
            ...submitErrors,
            ...validate(
              { name: [key + "-" + index], value: destination[key] },
              { required: true }
            ),
          };
        });
      });
    }

    setFormState({
      ...formState,
      errors: submitErrors,
    });

    return submitErrors;
  }

  // get Ports list based on deriction (from, to) and index for multi destination form
  async function getAirPortsList(inputValue, direction, index) {
    console.log("getAirPortsListByCountryId", id);

    if (inputValue.length > 2) {
      const airProts = await fetchAirPorts({ search: inputValue });
      let result = airProts.map((item) => ({
        ...item,
        name: item !== "" ? item.names[locale] || item.names.en : item.names.en,
        value: item.id,
        label:
          item !== "" ? item.names[locale] || item.names.en : item.names.en,
      }));

      // for Multi-destination
      if (index > -1) {
        setAirPorts({ ...airPorts, [direction + "-" + index]: result });
      } else {
        setAirPorts({ ...airPorts, [direction]: result });
      }
    }
  }

  async function getAirPortsListByCountryId(id, direction, index) {
    const airProts = await fetchAirPortsByCountryId(Number(id));
    let result = airProts.map((item) => ({
      ...item,
      name: item !== "" ? item.names[locale] || item.names.en : item.names.en,
      value: item.id,
      label: item !== "" ? item.names[locale] || item.names.en : item.names.en,
    }));

    // for Multi-destination
    if (index > -1) {
      setAirPorts({ ...airPorts, [direction + "-" + index]: result });
    } else {
      setAirPorts({ ...airPorts, [direction]: result });
    }
  }

  // get airlines list
  async function getListAirlines(inputValue) {
    if (inputValue.length > 2) {
      const Airlines = await fetchTransporters(2, inputValue);
      let result = Airlines.map((item) => {
        return {
          id: item.id,
          name: item.name,
          label: item.name,
          code: item.code,
        };
      });
      setListAirlines(result);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) return;
    let { values } = formState;
    let requestData = {
      type,
      trips: isMultiDestination
        ? values.destinations.map((destination) => ({
            from_port_id: destination?.from?.id,
            to_port_id: destination?.to?.id,
            departure_date: moment(destination?.departure_date)
              .locale("en")
              .format("YYYY-MM-DD"),
          }))
        : [
            {
              from_port_id: values.from?.id,
              to_port_id: values?.to?.id,
              departure_date: moment(values?.departure_date)
                .locale("en")
                .format("YYYY-MM-DD"),
            },
          ],
      adults_count: values.adults,
      children_count: values.children,
      infants_count: values.infants ? values.infants : 0,
      cabin_class: values.class?.id,
      transporter_id: values.airline?.id,
      notes: values.note,
      client: {
        name: values.contact_name,
        email: values.contact_email,
        phone: values.contact_number,
        whatsapp_number: values.whatsapp,
      },
    };

    let res = await sendServiceBuilderRequest({
      id,
      data: requestData,
      serviceType: "flight",
    });
    if (res?.status === 200) {
      handleResponesNotification({
        alertType: "success",
        message: res?.data?.message || "Request Sent Successfully",
      });
      setFormState({ values: initialValues });
    }
  }

  return {
    formState,
    airPorts,
    listAirlines,
    setFormState,
    setAirPorts,
    setListAirlines,
    getAirPortsList,
    getAirPortsListByCountryId,
    getListAirlines,
    cabinClasses,
    handleSubmit,
  };
}
