import { useSBSDispatch, useSBSState } from "context/global";
import { useWebBuilderDispatch } from "context/webBuilder";
import Footer from "modules/WebBuilder-V2/Components/Content/Footer";
import HeroHeaderV2 from "modules/WebBuilder-V2/Components/Content/Hero/Header";
import {
  changeBorder,
  changeColorTheme,
  changeFont,
  changeFontColor,
  changeTitle,
} from "modules/WebBuilder-V2/helpers/styles";
import { useEffect, useState } from "react";
import { exchangeRate, getPageBuilderByCompany } from "services/webBuilder";
import { ReactComponent as WhatsappIcon } from "assets/images/whatsapp-icon.svg";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function DashboardLayout(props) {
  const { currency } = useSBSState();
  const dispatch = useWebBuilderDispatch();
  const sbsDispatch = useSBSDispatch();
  const [pageExtraData, setPageExtraData] = useState({
    isLoaded: false,
    theme: "",
    whatsappCode: null,
    whatsappNumber: null,
    companyName: null
  });
  const history = useHistory();

  const updateStyle = ({ name, value }) => {
    dispatch({
      type: "updateStyle",
      payload: {
        name,
        value,
      },
    });
  };

  const getPageDetails = async () => {
    let res;
    let isFirstTime = localStorage.getItem("first");
    res = await getPageBuilderByCompany(process.env.REACT_APP_WebsiteName);

    if (res?.status === 200) {

      res?.data?.country_id === 20 && !isFirstTime && sbsDispatch({ type: "setInitialCurrency", payload: "EGP" })

      let pageBuilderData = res.data.page_builder_config;
      const style = pageBuilderData?.style;

      sbsDispatch({ type: "setLoading", payload: false });

      setPageExtraData({
        isLoaded: true,
        theme: style?.theme,
        whatsappCode: res.data?.public_page_phone_code,
        whatsappNumber: res?.data?.public_page_phone,
        companyName: res?.data?.name?.en || res?.data?.name?.ar
      });
      dispatch({
        type: "setInitialData",
        payload: {
          ...pageBuilderData,
          has_marketplace: res?.data?.has_marketplace,
          active_modules: res?.data?.active_modules,
          // content: contentWithexchangeRates
          // exchangeRates: exchangeRates || [],
          business_email: res?.data?.business_email,
          companyCountryId: res?.data?.country_id,
          country: res?.data?.country,
          city: res?.data?.city,
        },
      });
      changeColorTheme(style?.color, updateStyle);
      changeBorder(style?.rounded, updateStyle);
      changeFont(style?.font, updateStyle);
      changeFontColor(style?.fontColor || "0,0,0", updateStyle);
      changeTitle(res?.data?.name?.en || res?.data?.name?.ar);

      // Delay fetching exchange rates
      const exchangeRates = await exchangeCurrencyRate(
        res.data.page_builder_config?.content,
        res.data.page_builder_config?.pagesData,
      );

      sbsDispatch({
        type: "setexchangeRates",
        payload: exchangeRates || []
      })
    }
  };

  async function exchangeCurrencyRate(content, pagesData) {
    const nonCurreniesItems = ["aboutUs", "contactUs", "faq"];
    const pagesDataContent = pagesData?.filter(i => i?.id !== "about-page")?.map(i => i?.content)?.flat(1)
    content = content.filter((item) => !nonCurreniesItems.includes(item?.type));

    const contentCurrencies = [...pagesDataContent, ...content].reduce((currencies, contentItem) => {
      return contentItem?.items?.reduce((currencies, item) => {
        if (item?.product_uuid) {
          currencies.add(item?.currency);
        }
        return currencies;
      }, currencies);
    }, new Set());

    let data = {
      from: Array.from(contentCurrencies),
      to: currency,
      amount: 1,
    };
    const res = await exchangeRate(data);
    return res;
  }

  useEffect(() => {
    history.replace({ ...history.location, state: undefined });
    getPageDetails();

    return () => { };
  }, [window]);



  function contactWithWhatsapp(whatsappCode, whatsappNumber) {
    if (!whatsappCode || !whatsappNumber) return;
    whatsappCode =
      whatsappCode[0] === "+" ? whatsappCode.substring(1) : whatsappCode;
    whatsappNumber =
      whatsappNumber[0] === "0" ? whatsappNumber.substring(1) : whatsappNumber;
    let whatsAppLink = `https://wa.me/${whatsappCode}${whatsappNumber}`;
    window.open(whatsAppLink, "_blank").focus();
  }

  return (
    <>
      <Helmet>
        <title>{pageExtraData.companyName || process.env.REACT_APP_WebsiteName}</title>
        <meta name="description" content={pageExtraData.companyName || process.env.REACT_APP_WebsiteName} />
      </Helmet>
      <div
        className={`bg-white web-builder-preview ${pageExtraData?.theme ? pageExtraData.theme : "default"
          }`}
        id="main-web-builder"
      >
        {/* <Header /> */}
        {pageExtraData.isLoaded ? (
          <div className="" {...props}>
            <HeroHeaderV2 />
            <div style={{ minHeight: "100vh" }}>{props.children}</div>
            <Footer />
          </div>
        ) : null}

        {pageExtraData.whatsappCode && pageExtraData.whatsappNumber && (
          <button
            className="btn shadow-none whatsapp-btn"
            onClick={() =>
              contactWithWhatsapp(
                pageExtraData.whatsappCode,
                pageExtraData.whatsappNumber
              )
            }
          >
            <WhatsappIcon />
          </button>
        )}
      </div>

    </>
  );
}
