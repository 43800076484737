// Assets
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import Locale from "translations";
import { Link } from "react-router-dom";
import { useSBSState } from "context/global";
import useExchangeRate from "hooks/useExchangeRate";

export default function TourCardThemeTen(props) {
  let {
    name,
    name_localized,
    price,
    image,
    currency,
    product_uuid,
    initialName,
    locationName,
    city,
    sourceModule
  } = props;
  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });

  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const tourUrl = sourceModule === "service-builder" ? `/tours-service/${product_uuid}` : `/tours/${product_uuid}`;

  return (
    <Link to={tourUrl} className="tour-card"
      style={{
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(var(--web-page-primary-color), 0.6) 100%), url(${image || ""})`,
      }}
    >
      <div className="card-content">
        <h4 className="title">{name_localized?.[locale] || name || initialName}</h4>
        {/* package name and location */}
        <div className="card-details d-flex gap-10">
          <p className="location">
            <LocationIcon />
            <span className="mx-2">
              {locationName?.[locale] || locationName?.en || locationName}
              {city && <span>, {city}</span>}
            </span>
          </p>
        </div>
        {exchangedPrice&& 
          <p className="price">
            <span>{webBuilder.from}</span>
            <span className="amount truncate-price">{exchangedPrice}</span>{" "}
            <span className="currency">{exchangeCurrency}</span>
          </p>
        }
        {+price === 0 && 
          <p className="price">
            <span>{webBuilder.priceOnRequest}</span>
          </p>
        }
      </div>
    </Link>
  );
}
