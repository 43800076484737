import TransferCardTheme7 from "./TransferCardTheme7";

export default function TransferTheme7View({ details }) {
  const { items, title, body } = details;
  console.log({ details });

  return (
    <div className="home-page-transfer-section">
      <div className="web-builder-content custom-container">
        <div className="home-page-transfer-section__content">
          <div className="home-page-transfer-section__image-wrapper">
            <div>
              <img src={items[0]?.image || ""} alt="" />
            </div>
          </div>
          <div className="home-page-transfer-section__details">
            <div className="mb-4">
              <h2 className="home-page-transfer-section__title">{title}</h2>
              <p className="home-page-transfer-section__description">{body}</p>
            </div>
            <ul className="home-page-transfer-section__cards-list">
              {items.map((item, index) => (
                <li key={item.id}>
                  <TransferCardTheme7 item={item} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
