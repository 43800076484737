import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import TextField from "components/Form/TextField/TextField";
import validate from "helpers/validate";
import { Fragment, useEffect } from "react";
import PaxSelector from "./PaxSelector";
import SelectField from "components/Form/SelectField/SelectField";
import { PlusIcon as Plus } from "components/Icons";
import { CloseIcon } from "modules/WebBuilder-V2/shared/icons";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import Locale from "translations";
import useFlightForm from "./useFlightForm";
import { useParams } from "react-router-dom";

const requiredFields = [
  "adults",
  "class",
  "airline",
  "contact_name",
  "contact_email",
  "contact_number",
  "whatsapp",
  "note",
];

const initialValues = {
  destinations: [{ from: null, to: null, departure_date: null }],
  adults: 1,
  children: 0,
  infants: 0,
  class: null,
  airline: null,
  contact_name: "",
  contact_email: "",
  contact_number: "",
  whatsapp: "",
  note: "",
};

export default function MultiDestinationForm({ flightDetails }) {
  const { marketPlace, webBuilder } = Locale;

  const {
    formState,
    setFormState,
    airPorts,
    setAirPorts,
    listAirlines,
    setListAirlines,
    getAirPortsList,
    getAirPortsListByCountryId,
    getListAirlines,
    cabinClasses,
    handleSubmit,
  } = useFlightForm({
    initialValues,
    requiredFields,
    type: "multi-destination",
  });

  const from_port = flightDetails?.service?.trips[0]?.from_port || null;
  const to_port = flightDetails?.service?.trips[0]?.to_port || null;
  const selectedDestination = flightDetails?.service?.destination || null;

  function handleDestinationInputs({ key, value, index, isSelectValue }) {
    let cloneDestinations = [...formState.values.destinations];
    cloneDestinations[index] = { ...cloneDestinations[index], [key]: value };
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        destinations: cloneDestinations,
      },
      ...(isSelectValue && {
        errors: {
          ...formState.errors,
          ...validate({ name: [`${key}-${index}`], value }, { required: true }),
        },
      }),
    });
  }

  function addNewDestination() {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        destinations: [
          ...formState.values.destinations,
          { from: null, departure_date: null },
        ],
      },
    });
  }

  function removeDestination(index) {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        destinations: formState.values.destinations.filter(
          (_, i) => i !== index
        ),
      },
    });
  }

  useEffect(() => {
    // set onlyt the first row of destinations
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        destinations: [
          {
            from: from_port,
            to: to_port,
          },
        ],
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightDetails]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 p-0">
          {formState.values.destinations.map((destination, index) => (
            <Fragment key={index}>
              <div className="row m-0">
                {/* destination from */}
                <div className="col-4 col-md-3">
                  <AutoCompleteField
                    haslabel={false}
                    labelInner={true}
                    isImage={false}
                    label={marketPlace.From}
                    isSearchable={true}
                    listAuto={airPorts["from-" + index]}
                    setListAuto={setAirPorts}
                    getListAuto={(e) => getAirPortsList(e, "from", index)}
                    value={destination?.from?.name || ""}
                    onChange={(e) => {
                      handleDestinationInputs({
                        key: "from",
                        value: { name: e },
                        index,
                        isSelectValue: false,
                      });
                    }}
                    onSelectValue={(e) => {
                      handleDestinationInputs({
                        key: "from",
                        value: e,
                        index,
                        isSelectValue: true,
                      });
                    }}
                    color={
                      formState.errors?.[`from-${index}`]?.required
                        ? "danger"
                        : ""
                    }
                    errors={formState.errors?.[`from-${index}`]}
                  />
                </div>

                <div className="col-4 col-md-3">
                  <AutoCompleteField
                    haslabel={false}
                    labelInner={true}
                    isImage={false}
                    label={marketPlace.To}
                    isSearchable={true}
                    listAuto={airPorts["to-" + index]}
                    setListAuto={setAirPorts}
                    getListAuto={(e) =>
                      selectedDestination
                        ? index === 0
                          ? getAirPortsListByCountryId(
                              destination?.country_id,
                              "from"
                            )
                          : getAirPortsList(e, "to")
                        : getAirPortsList(e, "to")
                    }
                    value={destination?.to?.name || ""}
                    onChange={(e) => {
                      handleDestinationInputs({
                        key: "to",
                        value: { name: e },
                        index,
                        isSelectValue: false,
                      });
                    }}
                    onSelectValue={(e) => {
                      handleDestinationInputs({
                        key: "to",
                        value: e,
                        index,
                        isSelectValue: true,
                      });
                    }}
                    color={
                      formState.errors?.[`to-${index}`]?.required
                        ? "danger"
                        : ""
                    }
                    errors={formState.errors?.[`to-${index}`]}
                  />
                </div>

                {/* destination departure date */}
                <div className="col-4 col-md-3">
                  <DatePickerField
                    haslabel={false}
                    labelInner
                    label={marketPlace.departureDate}
                    placeholder={webBuilder.date}
                    name={`departure_date-${index}`}
                    date={destination.departure_date}
                    onChangeDate={(date) => {
                      handleDestinationInputs({
                        key: "departure_date",
                        value: date,
                        index,
                        isSelectValue: true,
                      });
                    }}
                    errors={formState.errors?.[`departure_date-${index}`]}
                    color={
                      formState.errors?.[`departure_date-${index}`]?.required
                        ? "danger"
                        : ""
                    }
                  />
                </div>

                {index !== 0 && (
                  <div className="col-2 p-1 mt-1">
                    <button
                      type="button"
                      className="remove-destination-btn btn btn-danger"
                      onClick={() => removeDestination(index)}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                )}
              </div>

              {index === formState.values.destinations.length - 1 && (
                <div className="col-12 col-md-4 p-1">
                  <button
                    type="button"
                    className="add-more-destination-btn btn btn-primary h-100 "
                    onClick={addNewDestination}
                  >
                    {webBuilder.addMoreDestination} <Plus />
                  </button>
                </div>
              )}
            </Fragment>
          ))}
        </div>

        {/* pax */}
        <div className="col-12 col-md-4">
          <PaxSelector
            isError={formState.errors?.adults?.required}
            onChange={(adults, children, infants) => {
              setFormState({
                ...formState,
                values: {
                  ...formState.values,
                  adults: adults === 0 ? null : adults,
                  children: children === 0 ? null : children,
                  infants: infants === 0 ? null : infants,
                },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "adults", value: adults === 0 ? null : adults },
                    { required: true }
                  ),
                },
              });
            }}
          />
        </div>

        {/* class option */}
        <div className="col-12 col-md-4">
          <SelectField
            haslabel={false}
            labelInner
            label={marketPlace.class}
            placeholder={webBuilder.select}
            name="class"
            options={cabinClasses}
            value={formState.values.class?.name}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, class: e },
                errors: {
                  ...formState.errors,
                  ...validate({ name: "class", value: e }, { required: true }),
                },
              });
            }}
            errors={formState.errors?.class}
            color={formState.errors?.class?.required ? "danger" : ""}
          />
        </div>

        {/* airline */}
        <div className="col-12 col-md-4">
          <AutoCompleteField
            haslabel={false}
            labelInner={true}
            isImage={false}
            label={marketPlace.airlines}
            isSearchable={true}
            listAuto={listAirlines}
            setListAuto={setListAirlines}
            getListAuto={getListAirlines}
            value={formState.values?.airline?.name || ""}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, airline: { name: e } },
              });
            }}
            onSelectValue={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, airline: e },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "airline", value: e },
                    { required: true }
                  ),
                },
              });
            }}
            errors={formState.errors?.airline}
            color={formState.errors?.airline?.required ? "danger" : ""}
          />
        </div>

        {/* contact name */}
        <div className="col-12 col-md-3">
          <TextField
            type="text"
            haslabel={false}
            labelInner
            label={webBuilder.contactName}
            placeholder={webBuilder.enteryourName}
            name="contact_name"
            value={formState.values.contact_name}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, contact_name: e.target.value },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "contact_name", value: e.target.value },
                    { required: true }
                  ),
                },
              });
            }}
            errors={formState.errors?.contact_name}
            color={formState.errors?.contact_name?.required ? "danger" : ""}
          />
        </div>

        {/* contact Email */}
        <div className="col-12 col-md-3">
          <TextField
            type="email"
            haslabel={false}
            labelInner
            label={webBuilder.contactEmail}
            placeholder={webBuilder.enteryourEmail}
            name="contact_email"
            value={formState.values.contact_email}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, contact_email: e.target.value },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "contact_email", value: e.target.value },
                    { required: true, email: true }
                  ),
                },
              });
            }}
            errors={formState.errors?.contact_email}
            color={
              formState.errors?.contact_email?.required ||
              formState.errors?.contact_email?.email
                ? "danger"
                : ""
            }
          />
        </div>

        {/* contact phone number */}
        <div className="col-12 col-md-3">
          <TextField
            type="number"
            haslabel={false}
            labelInner
            label={webBuilder.contactNumber}
            placeholder={webBuilder.enteryourNumber}
            name="contact_number"
            value={formState.values.contact_number}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, contact_number: e.target.value },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "contact_number", value: e.target.value },
                    { required: true }
                  ),
                },
              });
            }}
            errors={formState.errors?.contact_number}
            color={formState.errors?.contact_number?.required ? "danger" : ""}
          />
        </div>

        {/* whatsapp */}
        <div className="col-12 col-md-3">
          <TextField
            type="number"
            haslabel={false}
            labelInner
            label={webBuilder.whatsApp}
            placeholder={webBuilder.enteryourNumber}
            name="whatsapp"
            value={formState.values.whatsapp}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, whatsapp: e.target.value },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "whatsapp", value: e.target.value },
                    { required: true }
                  ),
                },
              });
            }}
            errors={formState.errors?.whatsapp}
            color={formState.errors?.whatsapp?.required ? "danger" : ""}
          />
        </div>

        {/* notes */}
        <div className="col-12 textarea-col">
          <TextField
            haslabel={false}
            labelInner
            label={webBuilder.note}
            placeholder={webBuilder.enteryourNote}
            name="note"
            value={formState.values.note}
            onChange={(e) => {
              setFormState({
                ...formState,
                values: { ...formState.values, note: e.target.value },
                errors: {
                  ...formState.errors,
                  ...validate(
                    { name: "note", value: e.target.value },
                    { required: true }
                  ),
                },
              });
            }}
            errors={formState.errors?.note}
            color={formState.errors?.note?.required ? "danger" : ""}
          />
        </div>

        <div className="col-12">
          <button
            type="submit"
            className="submit-button btn btn-primary mt-1 w-100 p-2"
          >
            {webBuilder.requestTicket}
          </button>
        </div>
      </div>
    </form>
  );
}
