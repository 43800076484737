import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import Carousel from "react-multi-carousel";
import { useSBSState } from "context/global";
import ButtonGroup from "modules/WebBuilder-V2/shared/CarouselButtonGroup";
import TransferCardTeme1 from "./TransferCardTheme1";
import { useState } from "react";

export default function TransferTheme1View({ details }) {
  const { locale } = useSBSState();

  // the carousel is having a clickable items 
  // so we need to prevent clicking while sliding
  const [isSliding, setIsSliding] = useState(false);

  const { id, body, title, items } = details;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1.2,
    },
  };

  return (
    <>
      {items?.length > 0 ? (
        <div className="web-builder-content-transfer" id={id}>
          <div className="custom-container">
            <div className="service-top-section">
              <ServicesContent header={title} body={body} />
            </div>

            <div className="tours-details services-carousel-container position-relative">
              <Carousel
                beforeChange={() => setIsSliding(true)}
                afterChange={() => setIsSliding(false)}
                responsive={responsive}
                itemClass={"transfer-slide"}
                className=""
                arrows={false}
                slidesToSlide={1}
                keyBoardControl={true}
                customButtonGroup={<ButtonGroup />}
                renderButtonGroupOutside={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                infinite={true}
                rtl={locale === "ar"}
              >
                {items.map((item, index) => (
                  <div className="pe-2" key={item.id || `transfer-${index}`}>
                    <TransferCardTeme1 {...item} isSliding={isSliding} itemType="transfer" />
                  </div>
                ))}
              </Carousel>
            </div>

            <div className="tours-details responsive">
              {items.map((item, index) => (
                <div className="pe-2" key={item.id || `transfer-${index}`}>
                  <TransferCardTeme1 {...item} itemType="transfer" />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
