import BreadCrumb from "modules/WebBuilder-V2/shared/BreadCrumb";
import flightImage from "assets/images/webBuilder/flight-service-background.jpg";
import FlightRequestForm from "./FlightRequestForm";
import Locale from "translations";
export default function FlightServiceView() {
  const { webBuilder } = Locale;

  const links = [
    {
      name: webBuilder.flightService,
      url: "",
    },
  ];

  return (
    <div className="service-view-wrapper">
      <div className="flight-service-page">
        <BreadCrumb links={links} />

        <div className="content-wrapper">
          <div className="content-image">
            <img src={flightImage} alt="flight" />
          </div>
          <div className="content-details">
            <FlightRequestForm />
          </div>
        </div>
      </div>
    </div>
  );
}
