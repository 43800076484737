import { useSBSState } from "context/global";
import { MapPin } from "modules/WebBuilder-V2/shared/icons";
import Rating from "modules/WebBuilder-V2/shared/Rating";

export default function HotelCard({ service }) {
  const { locale } = useSBSState();

  return (
    <>
      <div className="service-card__image">
        <img src={service?.item?.images[0]} alt="Hotel" />
      </div>
      <div className="service-card__content">
        <div className="d-flex justify-content-between align-items-center gap-1">
          <h3 className="service-card__title">
            {service?.item?.hotel_name?.[locale] ||
              service?.item?.hotel_name.en}
          </h3>
          {/* <span className="service-card__tag green">
            {service?.item?.is_refundable ? "Refundable" : "Non-Refundable"}
          </span> */}
        </div>
        <div className="service-card__rating">
          <Rating rating={service?.item?.hotel_stars || 0} />
          {/* <span className="service-card__tag-rating green">
            {service?.item?.is_refundable ? "Refundable" : "Non-Refundable"}
          </span> */}
        </div>
        <div className="service-card__location">
          <MapPin />
          <span>{service?.item?.hotel_address?.[locale] || service?.item?.hotel_address.en}</span>
        </div>
        {/* <div className="service-card__stay-duration">
            <Moon />
            <span>
              You will stay here {service?.item?.nights_count} night
              {service?.item?.nights_count > 1 ? "s" : null}
            </span>
          </div> */}
      </div>
    </>
  );
}
