import { useWebBuilderState } from 'context/webBuilder';
import OtherCardTheme1 from 'modules/WebBuilder-V2/Components/Content/OtherServices/theme1/OtherCardTheme1';
import OtherCardTheme4 from 'modules/WebBuilder-V2/Components/Content/OtherServices/theme1/OtherCardTheme1';
import OtherCardTheme10 from 'modules/WebBuilder-V2/Components/Content/OtherServices/theme10/OtherCardTheme10';
import OtherCardTheme2 from 'modules/WebBuilder-V2/Components/Content/OtherServices/theme2/OtherCardTheme2';
import OtherCardTheme5 from 'modules/WebBuilder-V2/Components/Content/OtherServices/theme5/OtherCardTheme5';
import OtherCardTheme7 from 'modules/WebBuilder-V2/Components/Content/OtherServices/theme7/OtherCardTheme7';


export default function CustomPageOthers({ details, title }) {
  const { style: { theme } } = useWebBuilderState();
  const { id, items } = details;

  const renderTransferView = (item) => {
    return {
      "theme-1": <OtherCardTheme1 item={item} itemType="transfer" />,
      "theme-2": <OtherCardTheme2 item={item} itemType="transfer" />,
      "theme-4": <OtherCardTheme4 item={item} itemType="transfer" />,
      "theme-5": <OtherCardTheme5 item={item} itemType="transfer" />,
      "theme-7": <OtherCardTheme7 item={item} itemType="transfer" />,
      "theme-10": <OtherCardTheme10 item={item} itemType="transfer" />,
    };
  };


  return (
    <>
      <h3 className="section-title mb-3 font-weight-bold">{title}</h3>
      <div className="web-builder-content-other py-0" id={id}>
        <div className="otherservice-cards-container">
          {items?.length > 0 && items?.map(item => {
            return (
              <>
                {renderTransferView(item)[theme]}
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}
