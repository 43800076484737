import { useWebBuilderState } from "context/webBuilder";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import Locale from "translations";
import { Link } from "react-router-dom";
import { Profile2Users, ProfileIcon } from "modules/WebBuilder-V2/shared/icons";

export default function TransferCardTeme1(props) {
  const { selectedLanguage } = useWebBuilderState();
  const { marketPlace, productElements, webBuilder } = Locale;

  const {
    image,
    country,
    name,
    trip_type,
    name_localized,
    product_uuid,
    withDriver,
    vehicleCapacity,
    isSliding,
  } = props;

  let countryName = country ? country[selectedLanguage] || country : "-";

  return (
    <Link
      to={`/transfer-service/${product_uuid}`}
      className="transfer-card"
      draggable={false}
      onDragStart={(e) => false}
      onClick={(e) => {
        if (isSliding) {
          e.preventDefault();
        }
      }}
    >
      <div className="card-img">
        {image ? (
          <img
            src={image}
            alt="transfer"
            width={100}
            draggable={false}
            onDragStart={(e) => false}
          />
        ) : null}
      </div>

      <div className="card-details">
        <div className="transfer-card-header">
          <span className="tag">{trip_type}</span>
          <h5 className="title">
            {name_localized ? name_localized[selectedLanguage] : name}
          </h5>
        </div>
        <p className="location">
          <LocationIcon />
          <span className="mx-2">{countryName}</span>
        </p>
        <p className="location">
          <Profile2Users />
          <span className="mx-2">
            {productElements.capacity} {vehicleCapacity} {marketPlace.adult}
          </span>
        </p>
        <p className="location">
          <ProfileIcon />
          <span className="mx-2">
            {withDriver ? webBuilder.withDriver : webBuilder.withoutDriver}
          </span>
        </p>
      </div>
    </Link>
  );
}
