import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import OtherCardTheme7 from "./OtherCardTheme7";

export default function OtherTheme7View({ details }) {
  const { id, body, title, items } = details;

  return (
    <>
      {items?.length > 0 ? (
        <section className="web-builder-content-other" id={id}>
          <div className="custom-container">
            <div className="service-top-section">
              <ServicesContent header={title} body={body} />
            </div>

            <div className="otherservice-cards-container">
              {items.map((item) => (
                <OtherCardTheme7 item={item} key={item?.product_uuid} />
              ))}
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}
