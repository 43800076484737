import HotelGridView from "modules/WebBuilder-V2/Components/Content/HotelService/HotelGridView";
import HotelCarouselView from "modules/WebBuilder-V2/Components/Content/HotelService/HotelCarouselView";
import { ServiceCardDetails } from "modules/WebBuilder-V2/shared/ServiceCardDetails";
import { useWebBuilderState } from "context/webBuilder";

export default function CustomPageHotels({ details, title }) {
  const { style } = useWebBuilderState();
  const { id, type, items, hotel_type } = details;



  let hotelViewProps = { items, type }

  function renderHotelView() {
    switch (style.theme || hotel_type) {
      case "theme-1":
        return <HotelGridView {...hotelViewProps} />
      case "theme-2":
        return <HotelCarouselView {...hotelViewProps} />
      case "theme-3":
        return <div className="slider-edit-container">
          {items.map((props, index) => (
            <ServiceCardDetails
              {...props}
              itemType="hotel"
              key={props?.id || index}
            />
          ))}
        </div>
      default:
        return <HotelCarouselView {...hotelViewProps} />
    }
  }

  return (
    <>
      <h3 className="section-title mb-3 font-weight-bold">{title}</h3>
      <div id={id} className="web-builder-content-hotels wb-hotels py-0">
        <div className="hotels-detials services-carousel-container">
          {items && renderHotelView()}
        </div>
      </div>
    </>
  );
}
