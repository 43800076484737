import React from "react";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-white.svg";
import { ReactComponent as Hotelcon } from "assets/images/webBuilder/hotel-white.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-white.svg";

import moment, { now } from "moment";
import { Link } from "react-router-dom";
import Locale from "translations";
import useExchangeRate from "hooks/useExchangeRate";
import { useSBSState } from "context/global";

export default function PackageCardwithImage(props) {
  const { webBuilder, productsBuilder } = Locale;
  const { locale } = useSBSState();
  const {
    image,
    name,
    name_localized,
    departure_date_at,
    return_date_at,
    stars,
    product_uuid,
    hotelName,
    hotel_name_locale,
    price,
    locationName,
    country,
    initialName,
    currency,
    sourceModule,
    nights_count
  } = props;

  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });
  let countryName = country ? (country?.[locale] || country?.en || "") : (locationName?.[locale] || locationName?.en || "");
  const packageUrl = sourceModule === "service-builder" ? `/package-service/${product_uuid}` : `/package/${product_uuid}`;
  const hotel_name = hotelName?.[locale] ? hotelName?.en : "Hotel Name";

  return (
    <Link
      to={product_uuid ? packageUrl : "/"}
      key={product_uuid}
    >
      <article
        className={`package-card-container ${!image ? "empty-service" : ""}`}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="package-card-details">
          {exchangedPrice && 
            <div className="price">
              <p>{webBuilder.from}</p>
              <span className="amount">
                {exchangedPrice}{" "}
                <span className="currency">
                  {" "}
                  {exchangeCurrency}
                </span>
              </span>
            </div>
          }
          {+price === 0 && 
            <div className="price">
              <p>{webBuilder.priceOnRequest}</p>
            </div>
          }
          <div className="package-info">
            <div className="rate">
              <div className="starts mb-2">
                {[...Array(5)].map((i, index) =>
                  index < stars ? (
                    <GoldenStarIcon key={`package-rate-${index}`} />
                  ) : (
                    <StarIcon key={`package-rate-${index}`} />
                  )
                )}
              </div>
              <h5 className="font-weight-bold mb-2 text-white">{name_localized?.[locale] || name_localized?.en || initialName || ""}</h5>
            </div>
            {countryName &&
              <div className="location mb-0">
                <LocationIcon /> <span className="ms-2rem">{countryName}</span>
              </div>
            }
            <div className="date">
              <CalendarIcon />
              {sourceModule === "service-builder" ?
                <span className="ms-3rem">{nights_count} {productsBuilder.days}</span>
                :
                <span className="ms-3rem">
                  {`${moment(departure_date_at || now()).format(
                    "DD MMM YYYY"
                  )} - ${moment(return_date_at || now()).format("DD MMM YYYY")}`}
                </span>
              }

            </div>
            {hotelName ? (
              <div className="hotel">
                <Hotelcon /> <span className="ms-2rem">{hotel_name}</span>
              </div>
            ) : null}
          </div>
        </div>
      </article>
    </Link>
  );
}
