import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
// import Carousel from "react-multi-carousel";
// import ButtonGroup from "modules/WebBuilder-V2/shared/CarouselButtonGroup";
import Locale from "translations";
import { useSBSState } from "context/global";
import { Link } from "react-router-dom";
import useExchangeRate from "hooks/useExchangeRate";
import ServiceSlider from "modules/WebBuilder-V2/shared/ServiceSlider";



export default function HotelTheme4({ items }) {

  // const { locale } = useSBSState();

  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 3,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 567 },
  //     items: 2,
  //   },
  //   mobile: {
  //     breakpoint: { max: 567, min: 0 },
  //     items: 1,
  //     partialVisibilityGutter: 50
  //   },
  // };
  
  return (
    // <Carousel
    //   responsive={responsive}
    //   itemClass={"service-slide"}
    //   className="hotel-container"
    //   slidesToSlide={1}
    //   arrows={false}
    //   keyBoardControl={true}
    //   customButtonGroup={<ButtonGroup />}
    //   renderButtonGroupOutside={true}
    //   renderArrowsWhenDisabled={true}
    //   additionalTransfrom={10 * 2}
    //   infinite={true}
    //   partialVisbile={true}
    //   rtl={locale === "ar"}
    // >
    //   {items.map((hotel) => {
    //     return (
    //       <HotelCardTheme4 hotel={hotel} />
    //     );
    //   })}
    // </Carousel>

    <ServiceSlider 
      items={items}
      className="hotel-container" 
      render={(hotel) => <HotelCardTheme4 hotel={hotel}/>} 
    />
  );
}


function HotelCardTheme4(props) {
  let {
    name,
    name_localized,
    price,
    image,
    currency,
    stars,
    product_uuid,
    locationName,
    city,
    sourceModule
  } = props.hotel;
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price: price, currency: currency });
  const hotelUrl = sourceModule === "service-builder" ? `/hotels-service/${product_uuid}` : `/hotels/${product_uuid}`;

  return (
    <div
      key={product_uuid}
      className="card-holder  d-flex flex-column h-100 flex-grow-1"
    >
      <div className={`service-img`}>
        {image ? (
          <img src={image} alt="our package" />
        ) : (
          <img
            src="https://back-sbs.dev.safa.asia/api/v1/files/fetch/476c2074-0c5e-4e82-a891-d5099530f7e6"
            alt="Safa Visa"
          />
        )}
      </div>

      <div className="card-caption-holder d-flex flex-column flex-grow-1">
        <h3 className="card-title mb-0">
          {name_localized?.[locale] || name}
        </h3>

        <div className="rate mb-2">
          {[...Array(5)].map((i, index) =>
            index < stars ? (
              <GoldenStarIcon key={index} />
            ) : (
              <StarIcon key={index} />
            )
          )}
        </div>

        {/* Hotel Location */}
        <div className="pb-2">
          <LocationIcon className="location-icon" />
          <span className="location">
            {locationName?.[locale] || locationName?.en || locationName}
            {city ? ` - ${city}` : null}
          </span>
        </div>

        {/* Card Action and Pricing */}
        <div className="card-action-holder d-flex flex-column flex-grow-1">
          {exchangedPrice && 
            <div className="card-price">
              <p className="label">{webBuilder?.startFrom}</p>

              <div className="d-flex align-items-center">
                <p className="price truncate-price">{exchangedPrice}</p>
                <p className="ms-1">
                  {exchangeCurrency}
                  /<span>{webBuilder?.night}</span>
                </p>
              </div>
            </div>
          }
          {+price === 0 &&
            <div className="card-price mb-2">
              <p>{webBuilder.priceOnRequest}</p>
            </div>
          }

          <Link to={hotelUrl} className="btn book-btn mt-auto" >{webBuilder?.bookNow}</Link>
        </div>
      </div>
    </div>
  )
}
