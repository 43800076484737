import TransferCardTheme5 from "./TransferCardTheme5";

export default function TransferTheme5View({ details }) {
  const { items, title, body } = details;

  return (
    <div className="home-page-transfer-section">
      <div className="web-builder-content custom-container">
        <div className="mb-4">
          <h2 className="home-page-transfer-section__title">{title}</h2>
          <p className="home-page-transfer-section__description">{body}</p>
        </div>
        <ul className="home-page-transfer-section__cards-list">
          {items.map((item, index) => (
            <li key={item.id}>
              <TransferCardTheme5 item={item} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
