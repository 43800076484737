import { CarIcon, LocationIcon } from "components/Icons";
import { useSBSState } from "context/global";
import useExchangeRate from "hooks/useExchangeRate";
import { Profile2Users } from "modules/WebBuilder-V2/shared/icons";
import { useHistory } from "react-router-dom";
import Locale from "translations";

export default function TransferCardTheme4({ item }) {
  const { locale } = useSBSState();
  const { marketPlace, webBuilder } = Locale;

  const history = useHistory();
  const {
    image,
    price,
    currency,
    country,
    name_localized,
    product_uuid,
    trip_type,
    withDriver,
    vehicleCapacity,
  } = item;

  const countryName = country ? country[locale] || country?.en : "-";
  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });

  return (
    <div className="home-page-transfer-card">
      <div className="home-page-transfer-card__image">
        <img
          src={image}
          alt="transfer"
          draggable={false}
          onDragStart={(e) => false}
          onMouseDown={(e) => false}
        />
      </div>
      <div className="home-page-transfer-card__content">
        <div className="home-page-transfer-card__header">
          <h5 className="home-page-transfer-card__title">
            {name_localized[locale] || name_localized?.en}
          </h5>
          <span className="home-page-transfer-card__tag">{trip_type}</span>
        </div>
        <div className="home-page-transfer-card__body">
          <p className="home-page-transfer-card__location">
            <LocationIcon />
            <span>{countryName}</span>
          </p>
          <p className="home-page-transfer-card__driver">
            <CarIcon />
            <span>
              {withDriver ? webBuilder.withDriver : webBuilder.withoutDriver}
            </span>
          </p>
          <p className="home-page-transfer-card__capacity">
            <Profile2Users />
            <span>
              {vehicleCapacity} {marketPlace.adult}
            </span>
          </p>
        </div>
      </div>
      <hr className="home-page-transfer-card__divider" />
      <div className="home-page-transfer-card__footer">
        {exchangedPrice && 
          <div className="home-page-transfer-card__price">
            <span className="home-page-transfer-card__price-from">
              {webBuilder.startFrom}
            </span>
            <div className="home-page-transfer-card__price-value">
              <span className="home-page-transfer-card__price-value-number truncate-price">
                {exchangedPrice}
              </span>
              <div className="home-page-transfer-card__price-value-per">
                <span className="home-page-transfer-card__price-value-per-currency">
                  {exchangeCurrency}
                </span>
                <span className="home-page-transfer-card__price-value-per-time">
                  / {webBuilder.night}
                </span>
              </div>
            </div>
          </div>
        }
        {+price === 0 &&
          <div className="home-page-transfer-card__price">
            <p>{webBuilder.priceOnRequest}</p>
          </div>
        }
        <button
          className="btn home-page-transfer-card__button"
          onClick={() => history.push(`/transfer-service/${product_uuid}`)}
        >
          {webBuilder.view}
        </button>
      </div>
    </div>
  );
}
