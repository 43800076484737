import BreadCrumb from "modules/WebBuilder-V2/shared/BreadCrumb";
import ContentCardWrapper from "modules/WebBuilder-V2/shared/ContentCardWrapper";
import ServiceCarousel from "modules/WebBuilder-V2/shared/ServiceCarousel";
import ServiceTabBar from "modules/WebBuilder-V2/shared/ServiceTabBar";
import ServiceViewHeader from "modules/WebBuilder-V2/shared/ServiceViewHeader";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getBuilderServicerById } from "services/webBuilder";
import Locale from "translations";
import { HotelIcon } from "components/Icons";
import PackageRequestForm from "./PackageRequestForm";
import {
  AirPlan,
  CheckMark,
  GalleryRemove,
  Note,
  TaskSquare,
  Buildings,
  CameraIcon,
  BusIcon,
} from "modules/WebBuilder-V2/shared/icons";
import { useSBSState } from "context/global";
import TransportationCard from "./TransportationCard";
import ServiceCard from "./ServiceCard";
import HotelCard from "./HotelCard";
import AttractionCard from "./AttractionCard";
import FlightCard from "./FlightCard";
import { ReactComponent as RequiredDocIcon } from "assets/images/market-place/required-document-text.svg";
import ContentCardAccordion from "modules/WebBuilder-V2/shared/ContentCardAccordion";


export default function PackageServiceView() {
  const { webBuilder, productsBuilder } = Locale;
  const { locale, currency: currentCurrency } = useSBSState();


  const { id } = useParams();
  const [packageData, setPackageData] = useState(null);

  const initialTabs = [
    {
      name: webBuilder.description,
      icon: <Buildings />,
      hash: "#description",
    },
    {
      name: webBuilder.flight,
      icon: <AirPlan />,
      hash: "#flight",
    },
    {
      name: webBuilder.hotels,
      icon: <HotelIcon />,
      hash: "#hotels",
    },
    {
      name: webBuilder.attraction,
      icon: <CameraIcon />,
      hash: "#attraction",
    },
    {
      name: webBuilder.transportation,
      icon: <BusIcon />,
      hash: "#transportation",
    },
    {
      name: webBuilder.includeExeclude,
      icon: <TaskSquare />,
      hash: "#include-exclude",
    },
    {
      name: webBuilder.policies,
      icon: <Note />,
      hash: "#policies",
    },
  ];

  const [tabs, setTabs] = useState(initialTabs);

  const links = [
    {
      name: packageData?.service?.name?.[locale] || packageData?.name,
      url: "",
    },
  ];

  const filterServiceItemsByType = useCallback(
    (type = "") => {
      const serviceItems = packageData?.service?.items || [];
      return serviceItems.filter((item) => item.type === type);
    },
    [packageData?.service?.items]
  );

  const flights = useMemo(() => filterServiceItemsByType("builder_flight"),
    [filterServiceItemsByType]
  );

  const hotels = useMemo(() => filterServiceItemsByType("builder_hotel"),
    [filterServiceItemsByType]
  );

  const tours = useMemo(() => filterServiceItemsByType("builder_tour"),
    [filterServiceItemsByType]
  );

  const transportations = useMemo(() => filterServiceItemsByType("builder_transfer"),
    [filterServiceItemsByType]
  );

  useEffect(() => {
    async function getDataView() {
      const res = await getBuilderServicerById(id, currentCurrency);
      setPackageData(res.data?.data);
    }

    getDataView();
  }, [id]);

  useEffect(() => {
    if (packageData?.service?.items) {
      const newTabs = initialTabs.filter((tab) => {
        if (tab.name === "Flight") {
          return flights.length > 0;
        }
        if (tab.name === "Hotels") {
          return !!hotels.length > 0;
        }
        if (tab.name === "Attraction") {
          return tours.length > 0;
        }
        if (tab.name === "Transportation") {
          return transportations.length > 0;
        }

        return true;
      });

      setTabs(newTabs);
    }
  }, [
    flights.length,
    hotels.length,
    packageData,
    tours.length,
    transportations.length,
  ]);

  let inclusions = [];
  let exclusions = [];

  if (packageData?.service?.inclusions) {
    if (packageData?.service?.inclusions?.[locale]) {
      inclusions = packageData?.service?.inclusions?.[locale];
    } else if (packageData?.service?.inclusions?.en) {
      inclusions = packageData.service.inclusions.en;
    } else {
      inclusions = [];
    }
  }

  if (packageData?.service?.exclusions) {
    if (packageData?.service?.exclusions?.[locale]?.length > 0) {
      exclusions = packageData?.service?.exclusions?.[locale];
    } else if (packageData?.service?.exclusions?.en) {
      exclusions = packageData?.service.exclusions.en;
    } else {
      exclusions = [];
    }
  }
  const packageTerms = packageData?.service?.terms[locale] || packageData?.service?.terms?.en || "";

  return (
    <div className="service-view-wrapper">
      <BreadCrumb links={links} />

      <div className="service-view-container w-85 mx-auto pb-5">
        <ServiceViewHeader
          title={packageData?.service?.name?.[locale] || packageData?.name?.en}
          stars={packageData?.service?.stars}
          LocationCountry={hotels[0]?.item?.country?.names[locale] || (hotels[0]?.item.country?.names.en) || ""}
          LocationCity={hotels[0]?.item?.city?.name}
          price={packageData?.price}
          currency={packageData?.currency}
          stayLenght={`${packageData?.service?.nights_count + 1} ${productsBuilder.days} / ${packageData?.service?.nights_count
            } ${webBuilder.nights}`}
          tag={packageData?.service?.product_type?.name}
        />

        <div className="service-top-section mb-5">
          <div className="service-content">
            <ServiceCarousel images={packageData?.service?.images} />

            <div className="mt-4 service-tabBar-wrapper">
              <ServiceTabBar tabs={tabs} />
            </div>

            {/* Description */}
            {packageData?.service?.description && (
              <div className="mt-4" id="description">
                <ContentCardWrapper title={webBuilder?.description}>
                  {/* body of ContentCard */}
                  <p className="card-body-text">
                    {packageData?.service?.description?.[locale] ||
                      packageData?.service?.description.en}
                  </p>
                </ContentCardWrapper>
              </div>
            )}

            {/* Flights */}
            {flights.length > 0 && (
              <div className="mt-4" id="flight">
                <ContentCardAccordion title={webBuilder.flight} initialOpen={true}>
                  <ul className="flight-cards-list">
                    {flights?.map((flight, index) => (
                      <>
                        <li key={flight.id}>
                          <ServiceCard service={flight} Card={FlightCard} />
                        </li>
                        <>
                          {index < flights.length - 1 ? <hr /> : null}
                        </>
                      </>
                    ))}
                  </ul>
                </ContentCardAccordion>
              </div>
            )}

            {/* Hotels */}
            {hotels.length > 0 && (
              <div className="mt-4" id="hotels">
                <ContentCardAccordion title={webBuilder.hotels}>
                  <ul className="hotel-cards-list">
                    {hotels?.map((hotel, index) => (
                      <>
                        <li key={hotel.id}>
                          <ServiceCard service={hotel} Card={HotelCard} />
                        </li>
                        <>
                          {index < hotels.length - 1 ? <hr /> : null}
                        </>
                      </>
                    ))}
                  </ul>
                </ContentCardAccordion>
              </div>
            )}

            {/* Tours */}
            {tours.length > 0 && (
              <div className="mt-4" id="attraction">
                <ContentCardAccordion title={webBuilder.attraction}>
                  <ul className="attraction-cards-list">
                    {tours?.map((tour) => (
                      <li key={tour.id}>
                        <ServiceCard service={tour} Card={AttractionCard} />
                      </li>
                    ))}
                  </ul>
                </ContentCardAccordion>
              </div>
            )}

            {/* Transportation */}
            {transportations.length > 0 && (
              <div className="mt-4" id="transportation">
                <ContentCardAccordion title={webBuilder.transportation}>
                  <ul className="transportation-cards-list">
                    {transportations?.map((transportation) => (
                      <li key={transportation.id}>
                        <ServiceCard
                          service={transportation}
                          Card={TransportationCard}
                        />
                      </li>
                    ))}
                  </ul>
                </ContentCardAccordion>
              </div>
            )}

            {/* Include & Exclude */}
            {(inclusions.length > 0 || exclusions.length > 0) && (
              <div className="mt-4" id="include-exclude">
                <ContentCardAccordion title={webBuilder.includeExeclude}>
                  {/* body of ContentCard */}
                  <ul className="include-exclude-list">
                    {inclusions?.length > 0 && (
                      <li>
                        <ul>
                          {inclusions.map((inclusion) => (
                            <li>
                              <span>
                                <CheckMark />
                              </span>
                              <span>{inclusion}</span>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )}
                    {exclusions?.length > 0 && (
                      <li>
                        <ul>
                          {exclusions?.map((exclusion) => (
                            <li>
                              <span>
                                <GalleryRemove />
                              </span>
                              <span>{exclusion}</span>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )}
                  </ul>
                </ContentCardAccordion>
              </div>
            )}

            {/* Policies */}
            {packageData?.service?.policy && (
              <div className="mt-4" id="policies">
                <ContentCardWrapper title={webBuilder.policies}>
                  <div className="policy">
                    <p className="card-body-text">
                      {packageData?.service?.policy?.[locale] ||
                        packageData?.service?.policy.en}
                    </p>

                    {packageData?.service?.cancellation && (
                      <div className="mt-3">
                        <h2 className="cancelation">{webBuilder.cancelationPolicy}</h2>
                        <p className="card-body-text mt-2">
                          {packageData?.service?.cancellation?.[locale] ||
                            packageData?.service?.cancellation.en}
                        </p>
                      </div>
                    )}
                  </div>
                </ContentCardWrapper>
              </div>
            )}
          </div>

          <div className="service-request">
            <div>
              <PackageRequestForm
                packageData={packageData}
                hasHotels={hotels.length > 0}
              />
            </div>
            {packageTerms &&
              <div className="package-item required-docs mt-4">
                <div className="d-flex align-items-center">
                  <RequiredDocIcon />
                  <h2 className="package-title mx-2">
                    {productsBuilder.termsAndConditions}
                  </h2>
                </div>
                <p className="mt-4">{packageTerms}</p>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
