import { useSBSState } from "context/global";
import { Link } from "react-router-dom";
import Locale from "translations";

export default function OtherCardTheme1({ item }) {
  const { locale } = useSBSState();
  const { webBuilder } = Locale;

  const { image, name, name_localized, product_uuid, description } = item;

  return (
    <article className="otherservice-card">
      <div className="card-img">
        {image ? <img src={image} alt="transfer" width={100} /> : null}
      </div>

      <div className="card-details">
        <h5 className="title">
          {name_localized ? name_localized[locale] : name}
        </h5>
        <p className="description">{description?.[locale] || description.en}</p>
      </div>
      <div className="card-bottom">
        <Link to={`/others-service/${product_uuid}`} className="btn cta-btn">
          {webBuilder.viewMore}
        </Link>
      </div>
    </article>
  );
}
