import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import FlightServiceCard from "./FlightCard";
import { useWebBuilderState } from "context/webBuilder";
import FlightServiceCardTheme4 from "./Theme4Card";
import ThemeSevenFlights from "./theme7";
import FlightServiceCardTheme5 from "./Theme5Card";
import Carousel from "react-multi-carousel";
import ButtonGroup from "modules/WebBuilder-V2/shared/CarouselButtonGroup";
import { useSBSState } from "context/global";
import FlightServiceBuilderCard from "./ServiceBuilderCard";

function FlightsService({ details }) {
  const { locale } = useSBSState();
  const { style } = useWebBuilderState();
  const { id, body, title, image, items, items_per_row } = details;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1,
      partialVisibilityGutter: 40
    },
  };

  function renderFlightView(item) {
    switch (style.theme) {
      case "theme-4":
        return <FlightServiceCardTheme4 {...item} itemType="flight" />
      case "theme-5":
        return <FlightServiceCardTheme5  {...item} itemType="flight" />
      case "theme-10":
        return <FlightServiceCardTheme4  {...item} itemType="flight" />
      default:
        return <FlightServiceCard {...item} itemType="flight" />
    }
  }

  const showArrows = items.length > responsive.desktop.items;

  return (
    <>
      {items?.length > 0 && 
        <section className="flight-section web-builder-content-flights wb-flights" id={id}>
          {items?.filter((item) => item?.product_uuid)?.length > 0
            ?
            // service builder cards 
            items?.[0]?.sourceModule === "service-builder"
              ?
              <div className="custom-container position-relative">
                <div className="service-top-section">
                  <ServicesContent header={title} body={body} />
                </div>
                <div className="flight-service-carousel services-carousel-container ">
                  <Carousel
                    responsive={responsive}
                    // itemClass={"pe-md-4"}
                    itemClass={"service-slide"}
                    className=""
                    arrows={false}
                    slidesToSlide={1}
                    keyBoardControl={true}
                    customButtonGroup={showArrows ? <ButtonGroup /> : null}
                    partialVisbile={true}
                    renderButtonGroupOutside={true}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    infinite={true}
                    rtl={locale === "ar"}
                  >
                    {items.map((item, index) => (
                      <div className="pe-2">
                        
                      <FlightServiceBuilderCard {...item} key={item.id || index} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>

              :

              // package builder cards 
              <div className="" >
                {style.theme === "theme-7" ?
                  <div className="theme_flight_section custom-container">
                    <ThemeSevenFlights
                      items={items}
                      title={title}
                      body={body}
                      image={image}
                    />
                  </div>
                  :
                  <div className="custom-container">
                    <div className="service-top-section">
                      <ServicesContent header={title} body={body} />
                    </div>
                    <div
                      className="flights-cards-container"
                      style={{
                        gridTemplateColumns: `repeat(${+items_per_row < items?.length ? items_per_row : items?.length
                          } , 1fr)`,
                      }}
                    >
                      {items.map((item) => {
                        return <>{renderFlightView(item)}</>
                      })}
                    </div>
                  </div>
                }
              </div>
            :
            null
          }
        </section>
      }
    </>
  );
}

export default FlightsService;
