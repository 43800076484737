import RoundTripForm from "./RoundTripForm";
import OneWayForm from "./OneWayForm";
import MultiDestinationForm from "./MultiDestinationForm";
import { useEffect, useState } from "react";
import Locale from "translations";
import { useSBSState } from "context/global";
import { Collapse, CardBody, Card } from "reactstrap";
import { CaretTopIcon } from "modules/WebBuilder-V2/shared/icons";
import { useParams } from "react-router-dom";
import { getBuilderServicerById } from "services/webBuilder";
import { formatPrice } from "helpers/utils";

export default function FlightRequestForm() {
  const [flightDetails, setFlightDetails] = useState(null);
  const isRoundTrip = flightDetails?.service?.with_return === "1";

  const [selectedForm, setSelectedForm] = useState(0);
  const { webBuilder, productsBuilder } = Locale;
  const { locale, currency: currentCurrency } = useSBSState();
  const { id } = useParams();

  const forms = [
    {
      title: webBuilder.roundTrip,
      component: <RoundTripForm flightDetails={flightDetails} />,
    },
    {
      title: webBuilder.oneWay,
      component: <OneWayForm flightDetails={flightDetails} />,
    },
    {
      title: webBuilder.multiDestination,
      component: <MultiDestinationForm flightDetails={flightDetails} />,
    },
  ];

  const handleFormChange = (index) => {
    setSelectedForm(index);
  };

  const getDisabledForm = (index) => {
    if (index === 0) {
      // Round trip
      return !isRoundTrip; // Disable if not round trip
    }

    if (index === 1) {
      // One way
      return isRoundTrip; // Disable if round trip
    }
  };

  useEffect(() => {
    async function fetchFlightDetails() {
      const res = await getBuilderServicerById(id, currentCurrency);
      if (res?.status === 200) {
        setFlightDetails(res.data.data);
        isRoundTrip ? setSelectedForm(0) : setSelectedForm(1);
      }
    }
    fetchFlightDetails();
  }, [currentCurrency, id, isRoundTrip]);

  return (
    <div className="form-wrapper">
      <div className="form-card-header">
        <h2 className="position-relative">
          {flightDetails?.service?.destination?.names?.[locale] ||
            flightDetails?.service?.destination?.name?.en ||
            ""}
          {flightDetails?.price ? (
            <span
              className="start-price badge bg-success text-white rounded-pill ml-2 position-absolute"
            >
              {webBuilder.startFrom} :{" "}
              {formatPrice(flightDetails?.price, { minimumFractionDigits: 0 })}{" "}
              {flightDetails.currency}
            </span>
          ) : (
            ""
          )}
        </h2>
        <p>{webBuilder.pleaseFillInYourRequestForm}</p>
      </div>

      <div>
        <ul className="radio-btn-group">
          {forms.map((form, index) => (
            <li key={index} className="radio-btn">
              <input
                type="radio"
                id={form.title}
                name="form"
                value={form.title}
                checked={selectedForm === index}
                onChange={() => handleFormChange(index)}
                disabled={getDisabledForm(index)}
              />
              <label htmlFor={form.title}>{form.title}</label>
            </li>
          ))}
        </ul>

        <div className="mt-3">{forms[selectedForm].component}</div>
      </div>
      <div className="row">
        <div className="clo-12 mt-4">
          {flightDetails?.service?.description?.[locale] ||
          flightDetails?.service?.description?.en ? (
            <div className="mb-2">
              <FlightDetailsCollapse
                title={webBuilder.description}
                body={
                  flightDetails?.service?.description?.[locale] ||
                  flightDetails?.service?.description?.en ||
                  ""
                }
              />
            </div>
          ) : (
            ""
          )}

          {flightDetails?.service?.terms?.[locale] ||
          flightDetails?.service?.terms?.en ? ( 
            <div className="mb-2">
              <FlightDetailsCollapse
                title={productsBuilder.termsAndConditions}
                body={
                  flightDetails?.service?.terms?.[locale] ||
                  flightDetails?.service?.terms?.en ||
                  ""
                }
              />
            </div>
           ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

function FlightDetailsCollapse({ title, body }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="flight-details-collapse">
      <button onClick={toggle} className="flight-details-collapse-btn">
        <span className="flight-details-collapse-title font-weight-bolder">{title}</span>
        <span
          className={`flight-details-collapse-icon ${isOpen ? "open" : ""}`}
        >
          <CaretTopIcon />
        </span>
      </button>
      <Collapse isOpen={isOpen} className="px-2">
        <Card>
          <CardBody
            className="flight-details-collapse-body"
            style={{ fontSize: 14 }}
          >
            {body}
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}
