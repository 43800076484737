import { useWebBuilderState } from "context/webBuilder";
import TransferTheme4View from "./theme4";
import TransferTheme5View from "./theme5";
import TransferTheme7View from "./theme7";
import TransferTheme10View from "./theme10";
import TransferTheme1View from "./theme1";
import TransferTheme2View from "./theme2";

function TransferService({ details }) {
  const {
    style: { theme },
  } = useWebBuilderState();

  const renderTransferView = () => {
    return {
      "theme-1": <TransferTheme1View details={details} />,
      "theme-2": <TransferTheme2View details={details} />,
      "theme-4": <TransferTheme4View details={details} />,
      "theme-5": <TransferTheme5View details={details} />,
      "theme-7": <TransferTheme7View details={details} />,
      "theme-10": <TransferTheme10View details={details} />,
    };
  };

  if (details.items.length === 0) return null;

  return renderTransferView()[theme] || <TransferTheme1View details={details} />
}

export default TransferService;
