const ids = {
  6: "hotels",
  "hotels-section": "hotels",
  1: "flights",
  "flight-section": "flights",
  4: "visa",
  7: "tours",
  "tours-section": "tours",
  "transport": "transfer"
}

export function handleSearch(countryId, proudctType, content = [], pagesData) {
  let serviceType = proudctType;

  let isProductTypePackage = proudctType?.id === 2 || proudctType?.id === 3 || proudctType?.id === 4 || proudctType?.id === 1 || proudctType?.id === 5;

  let pagesDataContent = [];
  // get all packages , hotels and tours from home and custom pages content
  for (let i = 0; i < pagesData?.length; i++) {
    let pageContent = pagesData[i]?.content;
    if (pageContent) {
      for (let j = 0; j < pageContent.length; j++) {
        if (pageContent[j].items.length > 0) {
          pagesDataContent.push(pageContent[j])
        }
      }
    }
  }

  let allContent = [...content, ...pagesDataContent]
  let searchResults = [];
  if (isProductTypePackage) {
    searchResults = allContent?.filter(item => item?.type?.toLowerCase() === "packages")
    searchResults = searchResults.map(res => res?.items?.filter(item => {
      return (
        item?.product_uuid
        && (item?.product_type?.id === serviceType?.id)
        && (item?.country_id === countryId || item?.items?.find(current => current?.item?.id === 6)?.itemable?.country?.id === countryId)
      )
    }
    )).flat(1)
  } else {
    // webBuilder[service?.type]
    searchResults = allContent?.filter(item => (
      item?.type?.toLowerCase() === proudctType?.id?.toLowerCase() ||
      item?.type?.toLowerCase() === ids[proudctType?.id] ||
      item?.id?.toLowerCase() === ids[proudctType?.id])
    );
    searchResults = searchResults.map(res => res?.items?.filter(item => {
      return (
        item?.product_uuid
        && (
          // for rest of the items types 
          item?.country_id === countryId ||
          // for flight items
          item?.flightItems?.find(flightItem => flightItem?.toCountry?.nationality_id === countryId) ||
          // for hotels
          item?.items?.find(current => current?.item?.id === 6)?.itemable?.country?.id === countryId
        )
      )
    }

    )).flat(1)
  }

  searchResults = [...new Map(searchResults.map(item => [item["product_uuid"], item])).values()];
  return searchResults
}