import { useSBSState } from "context/global";
import {
  AirPlan,
  AirPlanPath,
  GlobalIcon,
  VerticleAirPlanPath,
} from "modules/WebBuilder-V2/shared/icons";
import Locale from "translations";

export default function FlightCard({ service }) {
  const { locale } = useSBSState();
  const { marketPlace, webBuilder } = Locale;

  return (
    <>
      <div className="service-card__image">
        <img src={service?.item?.images[0]} alt="Flight" />
      </div>
      <div className="service-card__content">
        <h3 className="service-card__title">
          {service?.item?.name[locale] || service?.item?.name.en}
        </h3>

        <div className="service-card__location my-2">
          <GlobalIcon />
          <span>{service?.item?.destination?.names[locale]}</span>
        </div>

        <div className="flight-card">
          <div className="flight-content">
            {/* get from service?.item?.from_port */}
            <div className="airport-info">
              <div className="icon-container">
                <AirPlan />
              </div>
              <div className="airport-details">
                <div className="airport-code">
                  <span className="label">{marketPlace.Depart}</span>
                  <div className="code">
                    {service?.item?.from_port?.code || "-"}
                  </div>
                </div>
                <div className="name">
                  {service?.item?.from_port?.name || null}
                </div>
              </div>
            </div>

            <div className="flight-path">
              <div className="path-container">
                <div className="path-line">
                  <AirPlanPath />
                </div>
              </div>
            </div>
            
            <div className="verticle-flight-path">
              <div className="verticle-path-container">
                <div className="verticle-path-line">
                  <VerticleAirPlanPath />
                </div>
              </div>
            </div>

            {/* get from service?.item?.to_port */}
            <div className="airport-info">
              <div className="icon-container">
                <AirPlan />
              </div>
              <div className="airport-details">
              <div className="airport-code">
                <span className="label">{webBuilder.arrive}</span>
                  <div className="code">
                    {service?.item?.to_port?.code || "-"}
                  </div>
                </div>
                <div className="name">
                  {service?.item?.to_port?.name || null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
