import { MinusIcon as Minus, PlusIcon as Plus } from "components/Icons";
import { ChevronDown } from "modules/WebBuilder-V2/shared/icons";
import { useEffect, useRef, useState } from "react";
import Locale from "translations";

export default function PaxSelector({ onChange, isError }) {
  const { webBuilder } = Locale;

  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const incrementAdults = () => setAdults((prev) => prev + 1);
  const decrementAdults = () => setAdults((prev) => Math.max(0, prev - 1));
  const incrementChildren = () => setChildren((prev) => prev + 1);
  const decrementChildren = () => setChildren((prev) => Math.max(0, prev - 1));
  const incrementInfants = () => setInfants((prev) => prev + 1);
  const decrementInfants = () => setInfants((prev) => Math.max(0, prev - 1));

  const handleOnChange = (adults, children, infants) => {
    onChange(adults, children, infants);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="pax-selector" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="pax-selector__button"
      >
        <div className="pax-selector__content">
          <div className="pax-selector__label">{webBuilder.pax}</div>
          <div className="pax-selector__value">
            <span>
              {adults} {webBuilder.adults}, {children} {webBuilder.children}, {infants} {webBuilder.infants}
            </span>
            <ChevronDown className="pax-selector__chevron" />
          </div>
        </div>
      </button>

      {isError && (
        <span className="pax-selector__error-message">
          {webBuilder.pleaseSelectTheNumberOfPassengers}
        </span>
      )}

      {isOpen && (
        <div className="pax-selector__dropdown">
          <div className="pax-selector__row">
            <span>{webBuilder.adults}</span>
            <div className="pax-selector__controls">
              <button
                type="button"
                onClick={() => {
                  decrementAdults();
                  handleOnChange(adults - 1, children);
                }}
                className="pax-selector__control-button btn btn-primary"
                disabled={adults === 1}
              >
                <Minus className="pax-selector__icon" />
              </button>
              <span className="pax-selector__count">{adults}</span>
              <button
                type="button"
                onClick={() => {
                  incrementAdults();
                  handleOnChange(adults + 1, children);
                }}
                className="pax-selector__control-button btn btn-primary"
              >
                <Plus className="pax-selector__icon" />
              </button>
            </div>
          </div>
          <div className="pax-selector__row">
            <span>{webBuilder.children}</span>
            <div className="pax-selector__controls">
              <button
                type="button"
                onClick={() => {
                  decrementChildren();
                  handleOnChange(adults, children - 1);
                }}
                className="pax-selector__control-button btn btn-primary"
                disabled={children === 0}
              >
                <Minus className="pax-selector__icon" />
              </button>
              <span className="pax-selector__count">{children}</span>
              <button
                type="button"
                onClick={() => {
                  incrementChildren();
                  handleOnChange(adults, children + 1);
                }}
                className="pax-selector__control-button btn btn-primary"
              >
                <Plus className="pax-selector__icon" />
              </button>
            </div>
          </div>
          <div className="pax-selector__row">
            <span>{webBuilder.infants}</span>
            <div className="pax-selector__controls">
              <button
                type="button"
                onClick={() => {
                  decrementInfants();
                  handleOnChange(adults, children, infants - 1);
                }}
                className="pax-selector__control-button btn btn-primary"
                disabled={infants === 0}
              >
                <Minus className="pax-selector__icon" />
              </button>
              <span className="pax-selector__count">{infants}</span>
              <button
                type="button"
                onClick={() => {
                  incrementInfants();
                  handleOnChange(adults, children, infants + 1);
                }}
                className="pax-selector__control-button btn btn-primary"
              >
                <Plus className="pax-selector__icon" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
