import { useSBSState } from "context/global";
import { BusIcon, PeopleIcon, RoutingIcon } from "modules/WebBuilder-V2/shared/icons";
import Locale from "translations";

export default function TransportationCard({ service }) {
  const { locale } = useSBSState();
  const {webBuilder} = Locale;

  const formatedCycleType = service?.item?.cycle_type ? service?.item?.cycle_type.split("_").join(" ") : "";

  return (
    <>
      <div className="service-card__image">
        <img src={service?.item?.images[0]} alt="Transportation" />
      </div>
      <div className="service-card__content">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-lg-center gap-1">
          <h3 className="service-card__title">
            {service?.item?.name?.[locale] || service?.item?.name.en}
          </h3>
          <div className="service-card__tags-wrapper">
            <span className="service-card__tag blue">
              {service?.item?.with_driver
                ? webBuilder.withDriver
                : webBuilder.withoutDriver}
            </span>
            {/* <span className="service-card__tag green">
              {service?.item?.is_refundable
                ? "Refundable"
                : "Non-Refundable"}
            </span> */}
          </div>
        </div>

        <ul className="service-card__details mt-4">
          <li className="service-card__details__item">
            <BusIcon />
            <span>
              {service?.item?.vehicle_type_name}{" "}
              {service?.item?.vehicle_model}
            </span>
          </li>
          <li className="service-card__details__item">
            <PeopleIcon />
            <span>{service?.item?.car_capacity} {webBuilder.seats}</span>
          </li>
          {service?.item?.cycle_type ? (
            <li className="service-card__details__item">
              <RoutingIcon />
              <span className="text-capitalize">{formatedCycleType} {webBuilder.seats}</span>
            </li>
          ) : null}
        </ul>
      </div>
    </>
  );
}
