import { useEffect, useState } from "react";
// React-Router-Dom
import { useParams } from "react-router-dom";
// Translation
import Locale from "translations";
// React-Multi-Carsousel
import Carousel from "react-multi-carousel";
// Components
import TextField from "components/Form/TextField/TextField";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
// Shared Components
import BreadCrumb from "modules/WebBuilder-V2/shared/BreadCrumb";
import ContentCardWrapper from "modules/WebBuilder-V2/shared/ContentCardWrapper";
import SmsIcon from "assets/images/webBuilder/sms.svg";

// import CustomRightArrow from "../../../shared/CustomRightArrow";
// import CustomLeftArrow from "../../../shared/CustomLeftArrow";
// Assets
import placeholderSrc from "assets/images/placeHoleder.png"
import carSrc from "assets/images/webBuilder/car.png"
import EditIcon from "assets/images/webBuilder/form-edit-icon.svg";
import WhatsappIcon from "assets/images/webBuilder/whatsapp.svg";

// Helper
import validate, { isFormValid } from "helpers/validate";
import { useSBSState } from "context/global";
import { handleResponesNotification } from "helpers/utils";
// Moment
import moment from "moment";
// Services
import { getBuilderServicerById, sendServiceBuilderRequest } from "services/webBuilder";
import PhoneField from "components/Form/PhoneField/PhoneField";
import { isObject, isArray } from "lodash"

// ---------------------------------------------------------------------------------------------------

const regex = /^[a-zA-Z\u0600-\u06FF\s]+$/;

const numberOfCars = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
]

const TransferServiceView = () => {

  // **** Hooks 
  const { id } = useParams();
  const { webBuilder, productsBuilder } = Locale;
  const { allCountries, locale, currency: currentCurrency } = useSBSState();

  // *** States
  const [transferData, setTransferData] = useState(null);

  const [requestFrom, setRequestForm] = useState({
    destination_from: "",
    destination_to: "",
    data_from: '',
    date_to: '',
    number_of_cars: '',
    user_name: "",
    phone_code: "",
    phone: "",
    whatsapp_number: "",
    notes: "",
    email: ""
  });

  const images = transferData?.images || [carSrc, carSrc]
  const [errors, setErrors] = useState({});

  const hasTerms = transferData?.terms && !isArray(transferData?.terms) && isObject(transferData?.terms)

  // *** Logic function
  function checkFormErrors() {
    let submitErrors = {};
    Object.keys(requestFrom).forEach(key => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: key, value: requestFrom[key] },
          { required: true }
        )
      }
    });
    setErrors(() => submitErrors);
    return submitErrors;
  }

  async function sendRequet(e) {
    e.preventDefault();
    let formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) return
    const requestBody = {
      destination_from: requestFrom?.destination_from,
      destination_to: requestFrom?.destination_to,
      date_from: moment(requestFrom?.data_from).locale('en').format("YYYY-MM-DD"),
      date_to: moment(requestFrom?.date_to).locale('en').format("YYYY-MM-DD"),
      num_of_cars: requestFrom.number_of_cars?.value,
      notes: requestFrom?.notes,
      client: {
        name: requestFrom?.user_name,
        email: requestFrom?.email,
        phone: requestFrom?.phone,
        whatsapp_number: requestFrom?.whatsapp_number
      }
    }


    if (process.env.REACT_APP_WebsiteName) {
      const res = await sendServiceBuilderRequest({ id, serviceType: 'transfer', data: requestBody });
      if (res.status === 200) {
        handleResponesNotification({ alertType: "success", message: res?.data?.message || "Request Sent Successfully" });

        setRequestForm({
          destination_from: "",
          destination_to: "",
          data_from: '',
          date_to: '',
          number_of_cars: '',
          user_name: "",
          phone_code: null,
          phone: "",
          whatsapp_number: "",
          notes: "",
          email: ""
        });
      }
    }



  }

  const links = [
    {
      name: webBuilder?.transfer,
      url: ''
    },
  ]

  const getBuilderServicer = async () => {
    const res = await getBuilderServicerById(id, currentCurrency);
    if (res?.status === 200) {
      setTransferData(res?.data?.data?.service)
    }
  }

  useEffect(() => {
    getBuilderServicer()
  }, [])

  // -------- JSX Code --------
  return (
    <div className='service-view-wrapper'>

      <BreadCrumb links={links} />

      <div className="service-view-container w-85 mx-auto pb-5">

        {/* Carsouel and Info */}
        <div className="service-top-section mb-5">
          {/* Image Carsoul */}
          <div style={{ overflow: 'hidden' }} >
            <div className="package-carousel">
              <Carousel
                responsive={{
                  desktop: { breakpoint: { max: 4000, min: 0 }, items: 1 },
                }}
                itemClass={"hotel-slide"}
                className=""
                slidesToSlide={1}
                keyBoardControl={true}
                // customRightArrow={<CustomRightArrow />}
                // customLeftArrow={<CustomLeftArrow />}
                renderButtonGroupOutside={true}
                shouldResetAutoplay={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                infinite={true}
              >
                {images?.map((slide, index) => {
                  return (
                    <img
                      src={slide}
                      alt=""
                      width={'100%'}
                      key={slide || index}
                      onError={(e) => {
                        e.target.src = placeholderSrc;
                      }}
                    />
                  );
                })}
              </Carousel>
            </div>
          </div>

          {/* Transfer info */}
          <div className="info-holder">
            <div >
              <h2 className="service-title text-center mb-3">{webBuilder?.reserveYourTransport}</h2>
              <p className="info-row">{webBuilder?.model} : <span>{transferData?.vehicle_model}</span></p>
              <p className="info-row">{webBuilder?.type}  : <span>{transferData?.vehicle_type_name}</span></p>
              <p className="info-row">{webBuilder?.category} : <span>{transferData?.trip_type}</span></p>
              <p className="info-row">{webBuilder?.location} : <span>{transferData?.trip_type === 'umrah' ? transferData?.country?.name : `${transferData?.city?.name}, ${transferData?.country?.name}`}</span></p>
              <p className="info-row">{webBuilder?.capacity} : <span>{transferData?.car_capacity}</span></p>
              <p className="info-row">{webBuilder?.note} : <span>{transferData?.with_driver === 1 ? webBuilder?.withDriver : webBuilder?.withoutDriver || "-"}</span></p>
            </div>
            {transferData?.price ?            
              <div className="price-holder" style={{ marginTop: '28px' }}>
                <p className="bold">{webBuilder?.from}</p>
                <p className="price-info">
                  {transferData?.price} {' '}
                  <span>{transferData?.currency}</span>
                  <span>/{webBuilder?.per_day}</span>
                </p>
              </div> 
            : null
            }

          </div>
        </div>

        {/* terms */}
        {hasTerms &&
          <div className="mb-4">
            <ContentCardWrapper title={productsBuilder.termsAndConditions}>
              {/* body of ContentCard */}
              <p className='card-body-text'>
                {transferData?.terms[locale] || transferData?.terms.en}
              </p>
            </ContentCardWrapper>
          </div>
        }
        {/* Description */}
        <ContentCardWrapper title={webBuilder?.description}>
          {/* body of ContentCard */}
          <p className='card-body-text'>
            {transferData?.description[locale] || transferData?.description.en}
          </p>
        </ContentCardWrapper>

        {/* Request Form */}
        <div className="request-form-holder">
          <div className="form-header">
            <p className="service-title">{webBuilder?.requestForm}</p>
          </div>
          <form className="p-3" onSubmit={sendRequet}>
            <div className="row mx-2">
              {/* Destenation From */}
              <div className="col-md-4 col-12 mt-3">
                <TextField
                  haslabel={false}
                  id="destination_from"
                  name="destination_from"
                  placeholder={webBuilder.destination_from}
                  label={webBuilder?.destination_from}
                  value={requestFrom?.destination_from}
                  onChange={(e) => {
                    if (regex.test(e.target.value) || e.target.value === "") {
                      setRequestForm({ ...requestFrom, destination_from: e.target.value });
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: "destination_from", value: e.target.value },
                          { required: true }
                        ),
                      })
                    }
                  }}
                  color={errors?.destination_from?.required ? "danger" : ""}
                  errors={errors?.destination_from}
                />
              </div>

              {/* Destenation To*/}
              <div className="col-md-4 col-12 mt-3">
                <TextField
                  haslabel={false}
                  id="destination_to"
                  name="destination_to"
                  placeholder={webBuilder.destination_to}
                  label={webBuilder.destination_to}
                  value={requestFrom?.destination_to}
                  onChange={(e) => {
                    if (regex.test(e.target.value) || e.target.value === "") {
                      setRequestForm({ ...requestFrom, destination_to: e.target.value });
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: "destination_to", value: e.target.value },
                          { required: true }
                        ),
                      })
                    }
                  }}
                  color={errors?.destination_to?.required ? "danger" : ""}
                  errors={errors?.destination_to}
                />
              </div>

              {/* Number of Car*/}
              <div className="col-md-4 col-12 mt-3">
                <SelectField
                  haslabel={false}
                  placeholderColor="#dddcdc"
                  id="nationality"
                  name="nationality"
                  placeholder={webBuilder.number_of_cars}
                  label={webBuilder.number_of_cars}
                  options={numberOfCars}
                  value={requestFrom?.number_of_cars?.name || ""}
                  onChange={(e) => {
                    setRequestForm({ ...requestFrom, number_of_cars: e })
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "number_of_cars", value: e },
                        { required: true }
                      ),
                    })
                  }}
                  // isImage ={fasle}
                  // image={GlobalIcon}
                  // prependimage
                  color={errors?.number_of_cars?.required ? "danger" : ""}
                  errors={errors?.number_of_cars}
                />
              </div>

              {/* Date From*/}
              <div className="col-md-4 col-12 mt-3">
                <DatePickerField
                  label={webBuilder?.dateFrom}
                  haslabel={false}
                  placeholder={webBuilder?.dateFrom}
                  id="data_from"
                  name="data_from"
                  date={requestFrom?.data_from}
                  onChangeDate={(date) => {
                    setRequestForm({ ...requestFrom, data_from: date });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "data_from", value: date },
                        { required: true }
                      ),
                    })
                  }}
                  isOutsideRange={(day) =>
                    !day.isAfter(moment(new Date()).add(0, "d"), "day")
                  }
                  color={errors?.data_from?.required ? "danger" : ""}
                  errors={errors?.data_from}
                />
              </div>

              {/* Date To*/}
              <div className="col-md-4 col-12 mt-3">
                <DatePickerField
                  label={webBuilder?.dateTo}
                  haslabel={false}
                  placeholder={webBuilder?.dateTo}
                  id="date_to"
                  name="date_to"
                  date={requestFrom.date_to}
                  onChangeDate={(date) => {
                    setRequestForm({ ...requestFrom, date_to: date });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "date_to", value: date },
                        { required: true }
                      ),
                    })
                  }}
                  isOutsideRange={(day) =>
                    !day.isAfter(moment(new Date()).add(0, "d"), "day")
                  }
                  color={errors?.date_to?.required ? "danger" : ""}
                  errors={errors?.date_to}
                />
              </div>

              {/* Your Name */}
              <div className="col-md-4 col-12 mt-3">
                <TextField
                  haslabel={false}
                  id="your_name"
                  name="your_name"
                  placeholder={webBuilder?.your_name}
                  label={webBuilder?.your_name}
                  value={requestFrom?.user_name}
                  onChange={(e) => {
                    if (regex.test(e.target.value) || e.target.value === "") {
                      setRequestForm({ ...requestFrom, user_name: e.target.value });
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: "user_name", value: e.target.value },
                          { required: true }
                        ),
                      })
                    }
                  }}
                  isImage
                  image={EditIcon}
                  prependimage
                  color={errors?.user_name?.required ? "danger" : ""}
                  errors={errors?.user_name}
                />
              </div>

              <div className="col-md-4 col-12 mt-3">
                <TextField
                  type="email"
                  haslabel={false}
                  id="form-email"
                  name="form-email"
                  placeholder={webBuilder.email}
                  label={webBuilder.email}
                  value={requestFrom.email}
                  isImage
                  image={SmsIcon}
                  prependimage
                  onChange={(e) => {
                    setRequestForm({ ...requestFrom, email: e.target.value });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "email", value: e.target.value },
                        { required: true }
                      ),
                    });
                  }}
                  color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
                  errors={errors?.email}
                />
              </div>

              {/* Your phone */}
              <div className="col-md-4 col-12 mt-3">
                <PhoneField
                  countriesLookup={allCountries}
                  phoneCode={requestFrom?.phone_code}
                  phone={requestFrom.phone}
                  onSelectPhoneCode={(e) => {
                    setRequestForm({ ...requestFrom, phone_code: e });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "phone_code", value: e },
                        { required: true, }
                      ),
                    });
                  }}
                  onChangePhone={(e) => {
                    setRequestForm({ ...requestFrom, phone: e.target.value });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "phone", value: e.target.value },
                        { required: true, }
                      ),
                    });
                  }}
                  phoneCodeColor={errors?.phone_code?.required ? "danger" : ""}
                  phoneCodeError={errors?.phone_code}
                  phoneColor={errors?.phone?.required ? "danger" : ""}
                  phoneError={errors?.phone}
                />
              </div>

              {/* Whats App */}
              <div className="col-md-4 col-12 mt-3">
                <TextField
                  type="number"
                  haslabel={false}
                  id="whatsapp_number"
                  name="whatsapp_number"
                  placeholder={webBuilder.whatsapp}
                  label={webBuilder.whatsapp}
                  value={requestFrom.whatsapp_number}
                  isImage
                  image={WhatsappIcon}
                  prependimage
                  extraText={requestFrom?.phone_code?.phone_code || "---"}
                  extraTextPosition="prepend"
                  onChange={(e) => {
                    setRequestForm({ ...requestFrom, whatsapp_number: e.target.value });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "whatsapp_number", value: e.target.value },
                        { required: true }
                      ),
                    });
                  }}
                  color={errors?.whatsapp_number?.required ? "danger" : ""}
                  errors={errors?.whatsapp_number}
                />
              </div>

              {/* Notes */}
              <div className="col-12 mt-3">
                <textarea
                  id="notes"
                  name="notes"
                  cols="30"
                  rows="5"
                  className="textarea"
                  placeholder={webBuilder.notes}
                  value={requestFrom.notes}
                  onChange={(e) => {
                    setRequestForm({ ...requestFrom, notes: e.target.value })
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "notes", value: e.target.value },
                        { required: true }
                      ),
                    });
                  }}
                  style={{ borderColor: errors?.notes?.required ? "red" : "" }}
                />
                {errors?.notes?.required && <p className="text-danger">Notes Is Required</p>}
              </div>

              <div className="col-12 mt-3">
                <button type="submit" className="btn submit-btn w-100">
                  {webBuilder.sendRequest}
                </button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TransferServiceView;
