import { CarIcon, LocationIcon } from "components/Icons";
import { useWebBuilderState } from "context/webBuilder";
import useExchangeRate from "hooks/useExchangeRate";
import { Profile2Users } from "modules/WebBuilder-V2/shared/icons";
import { Link } from "react-router-dom";
import Locale from "translations";

export default function TransferCardTheme10({ item }) {
  const { selectedLanguage } = useWebBuilderState();
  const { marketPlace, webBuilder } = Locale;

  const {
    image,
    country,
    name_localized,
    product_uuid,
    withDriver,
    trip_type,
    vehicleCapacity,
    price,
    currency
  } = item;

  const countryName = country ? country[selectedLanguage] || country : "-";
  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });

  return (
    <Link to={`/transfer-service/${product_uuid}`}>
      <div className="home-page-transfer-card">
        <div className="home-page-transfer-card__image">
          <img src={image} alt="transfer" />
        </div>
        <div className="home-page-transfer-card__content">
          <div className="home-page-transfer-card__details">
            <h3 className="home-page-transfer-card__title truncate">
              {name_localized[selectedLanguage]}
            </h3>
            <div className="home-page-transfer-card__tag">{trip_type}</div>
          </div>
          <div className="home-page-transfer-card__body">
            <p className="home-page-transfer-card__location">
              <LocationIcon />
              <span>{countryName}</span>
            </p>
            <p className="home-page-transfer-card__driver">
              <CarIcon />
              <span>
                {withDriver ? webBuilder.withDriver : webBuilder.withoutDriver}
              </span>
            </p>
            <p className="home-page-transfer-card__capacity">
              <Profile2Users />
              <span>
                {vehicleCapacity} {marketPlace.adult}
              </span>
            </p>
          </div>
          <hr className="home-page-transfer-card__divider" />
          <div className="home-page-transfer-card__footer">
            {exchangedPrice &&
              <div className="home-page-transfer-card__price">
                <span className="home-page-transfer-card__price-label">{marketPlace.Price}</span>
                <span className="home-page-transfer-card__price-value truncate-price">{exchangedPrice}</span>
                <span className="home-page-transfer-card__price-currency">{exchangeCurrency}</span>
              </div>
            }
            {+price === 0 &&
              <div className="home-page-transfer-card__price py-1">
                <span className="home-page-transfer-card__price-currency text-capitalize">{webBuilder.priceOnRequest}</span>
              </div>
            }
          </div>
        </div>
      </div>
    </Link>
  );
}
