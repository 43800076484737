import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBuilderServicerById } from "services/webBuilder";
import ServiceCarousel from "modules/WebBuilder-V2/shared/ServiceCarousel";
import BreadCrumb from "modules/WebBuilder-V2/shared/BreadCrumb";
import Locale from "translations";
import ServiceViewHeader from "modules/WebBuilder-V2/shared/ServiceViewHeader";
import ContentCardWrapper from "modules/WebBuilder-V2/shared/ContentCardWrapper";
import ServiceTabBar from "modules/WebBuilder-V2/shared/ServiceTabBar";
import HotelRequestForm from "./HotelRequestForm";
import {
  Buildings,
  CheckMark,
  Note,
  TaskSquare,
} from "modules/WebBuilder-V2/shared/icons";
import { useSBSState } from "context/global";
import { isObject, isArray } from "lodash"


export default function HotelServiceView() {
  const { id } = useParams();
  const [hotelData, setHotelData] = useState(null);
  const { locale, currency: currentCurrency, } = useSBSState();

  const { webBuilder, productsBuilder } = Locale;

  const hasTerms = hotelData?.service?.terms && !isArray(hotelData?.service?.terms) && isObject(hotelData?.service?.terms);

  const links = [
    {
      name: hotelData?.service?.name?.[locale] || hotelData?.name,
      url: "",
    },
  ];

  let tabs = [
    {
      name: webBuilder.description,
      icon: <Buildings />,
      hash: "#description",
    },
    {
      name: webBuilder.facilities,
      icon: <TaskSquare />,
      hash: "#facilities",
    },
    {
      name: webBuilder.policies,
      icon: <Note />,
      hash: "#policies",
    }
  ];
  tabs = hasTerms ? [...tabs, {
    name: productsBuilder.termsAndConditions,
    icon: <Note />,
    hash: "#terms",
  }] : tabs

  useEffect(() => {
    async function getDataView() {
      const res = await getBuilderServicerById(id, currentCurrency);
      setHotelData(res.data?.data);
    }

    getDataView();
  }, [id]);




  return (
    <div className="service-view-wrapper">
      <BreadCrumb links={links} />

      <div className="service-view-container w-85 mx-auto pb-5">
        <ServiceViewHeader
          title={hotelData?.service?.name[locale] || hotelData?.name?.en}
          stars={hotelData?.service?.hotel_stars}
          LocationCountry={hotelData?.service?.country?.names[locale]}
          LocationCity={hotelData?.service?.city?.name}
          price={hotelData?.price}
          currency={hotelData?.currency}
          stayLenght={`${hotelData?.service?.nights_count + 1} ${productsBuilder.days} / ${hotelData?.service?.nights_count
            } ${webBuilder.nights}`}
        />

        <div className="service-top-section mb-5">
          <div className="service-content">
            <ServiceCarousel images={hotelData?.service?.images || []} />

            <div className="mt-4 service-tabBar-wrapper">
              <ServiceTabBar tabs={tabs} />
            </div>

            {hotelData?.service?.description && (
              <div className="mt-4" id="description">
                <ContentCardWrapper title={webBuilder?.description}>
                  {/* body of ContentCard */}
                  <p className="card-body-text">
                    {hotelData?.service?.description[locale] ||
                      hotelData?.service?.description.en}
                  </p>
                </ContentCardWrapper>
              </div>
            )}

            {hotelData?.service?.hotel_amenities[locale] && (
              <div className="mt-4" id="facilities">
                <ContentCardWrapper title={webBuilder.facilities}>
                  {/* body of ContentCard */}
                  <ul className="facilities-list">
                    {hotelData?.service?.hotel_amenities?.[locale]?.map(
                      (amenity, index) => (
                        <li>
                          <span>
                            <CheckMark />
                          </span>
                          <span>{amenity}</span>
                        </li>
                      )
                    )}
                  </ul>
                </ContentCardWrapper>
              </div>
            )}

            {hasTerms && (
              <div className="mt-4" id="terms">
                <ContentCardWrapper title={productsBuilder.termsAndConditions}>
                  <div className="policy">
                    <p className="card-body-text">
                      {hotelData?.service?.terms[locale] ||
                        hotelData?.service?.terms.en || "-"}
                    </p>
                  </div>
                </ContentCardWrapper>
              </div>
            )}

            {hotelData?.service?.policy && (
              <div className="mt-4" id="policies">
                <ContentCardWrapper title={webBuilder.policies}>
                  <div className="policy">
                    <p className="card-body-text">
                      {hotelData?.service?.policy[locale] ||
                        hotelData?.service?.policy.en}
                    </p>

                    <div className="mt-3">
                      <h2 className="cancelation">{webBuilder.cancelationPolicy}</h2>
                      <p className="card-body-text mt-2">
                        {hotelData?.service?.cancellation[locale] ||
                          hotelData?.service?.cancellation.en}
                      </p>
                    </div>
                  </div>
                </ContentCardWrapper>
              </div>
            )}
          </div>
          <div className="service-request">
            <HotelRequestForm hotelData={hotelData} />
          </div>
        </div>
      </div>
    </div>
  );
}
