import { useSBSState } from "context/global";
import { CheckMarkIcon } from "modules/market-place/ResponeServices/Tour/TourIcons";
import { ArrowIcon } from "modules/WebBuilder-V2/shared/icons";
import { useState } from "react";
import Carousel from "react-multi-carousel";
import Locale from "translations";

export default function ServiceCard({ service, Card }) {
  const { webBuilder } = Locale;

  const tabs = [
    {
      id: "overview",
      name: webBuilder.overview,
      component: Description,
    },
    {
      id: webBuilder.gallery,
      name: "Gallery",
      component: Gallery,
    },
    {
      id: "terms",
      name: webBuilder.policies,
      component: Terms,
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [showCarousel, setShowCarousel] = useState(false);

  return (
    <div className="service-card">
      <div className="service-card__header">
        <Card service={service} />
      </div>
      <div className="service-card__tabs">
        <ul className="service-card__tab-list">
          {tabs.map((tab) => (
            <li key={tab.id}>
              <button
                className={`service-card__tab ${currentTab.id === tab.id ? "active" : ""
                  }`}
                onClick={() => {
                  setCurrentTab(tab);
                  setShowCarousel(false);
                }}
              >
                {tab.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="service-card__tab-content">
        <currentTab.component
          service={service}
          showCarousel={showCarousel}
          setShowCarousel={setShowCarousel}
        />
      </div>
    </div>
  );
}

const Description = ({ service }) => {
  const { locale } = useSBSState();
  const { webBuilder } = Locale;

  let amenities = [];

  if (service?.item?.hotel_amenities) {
    if (service?.item?.hotel_amenities[locale]) {
      amenities = service?.item?.hotel_amenities[locale];
    } else {
      amenities = service?.item?.hotel_amenities.en;
    }
  }

  return (
    <div className="service-card__description service-card__tab-content-item">
      <h4 className="service-card__description__label">{webBuilder.description}</h4>
      <p className="service-card__description__value">
        {service?.item?.description?.[locale] || service?.item?.description.en}
      </p>

      {amenities?.length ? (
        <>
          <h4 className="service-card__description__label mt-4">{webBuilder.amenities}</h4>
          <ul className="service-card__amenties">
            {amenities.map((amenity, index) => (
              <li key={index} className="service-card__amenties__amenity">
                <CheckMarkIcon />
                {amenity}
              </li>
            ))}
          </ul>
        </>
      )
        : null
      }
    </div>
  );
};

const Gallery = ({ service, showCarousel, setShowCarousel }) => {
  const { packages } = Locale;

  return (
    <div className="service-card__tab-content-item">
      {showCarousel ? (
        <Carousel
          responsive={{
            desktop: { breakpoint: { max: 4000, min: 0 }, items: 1 },
          }}
          slidesToSlide={1}
          keyBoardControl={true}
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
          renderButtonGroupOutside={true}
          shouldResetAutoplay={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          infinite={true}
        >
          {service?.item?.images.map((image, index) => {
            return (
              <img
                src={image}
                alt=""
                key={`${image}-${index}`}
                onMouseDown={(e) => {
                  return false;
                }}
                draggable={false}
              />
            );
          })}
        </Carousel>
      ) : (
        <div className="service-card__gallery">
          <ul className="service-card__gallery__list">
            {service?.item?.images.map((image, index) => (
              <li key={index}>
                <img src={image} alt={`Hotel gallery ${index + 1}`} />
              </li>
            ))}

            <li className="service-card__gallery__list__view-all">
              <button onClick={() => setShowCarousel(true)}>
                <ArrowIcon />
                {packages.allPhotos}
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

const Terms = ({ service }) => {
  const { locale } = useSBSState();
  const { webBuilder, productsBuilder } = Locale;

  // let terms = []

  // if (service?.item?.terms) {
  //   if (service?.item?.terms[locale]) {
  //     terms = service?.item?.terms[locale];
  //   } else {
  //     terms = service?.item?.terms.en;
  //   }
  // }

  return (
    <div className="service-card__policies service-card__tab-content-item">
      {/* {terms?.length ? (
        <>
          <h4 className="service-card__description__label">
            {productsBuilder.termsAndConditions}
          </h4>
          {service?.item.terms.map((term) => (
            <p className="service-card__description__value mb-1">{term}</p>
          ))}
        </>
      ) : null} */}

      {service?.item?.policy ? (
        <>
          <h4 className="service-card__description__label mt-3">{webBuilder.policies}</h4>
          <p className="service-card__description__value">
            {service?.item?.policy?.[locale] || service?.item?.policy.en}
          </p>
        </>
      ) : null}

      {service?.item?.cancelation ? (
        <>
          <h4 className="service-card__description__label cancelation mt-3">
            {webBuilder.cancelationPolicy}
          </h4>
          <p className="service-card__description__value">
            {service?.item?.cancellation?.[locale] || service?.item?.cancellation.en}
          </p>
        </>
      ) : null}
    </div>
  );
};

const CustomRightArrow = ({ onClick, ...rest }) => {
  return (
    <button className="carousel-right-arrow" onClick={onClick}>
      <i className="fas fa-arrow-circle-right fa-1x" />
    </button>
  );
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
  return (
    <button className="carousel-left-arrow" onClick={onClick}>
      <i className="fas fa-arrow-circle-left fa-1x" />
    </button>
  );
};
