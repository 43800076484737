
import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import PackageCardwithImage from './PackageCardwithImage';

function OurPackagesWithImage({ details }) {
  const { id, title, body, items } = details;

  return (
    <div className="web-builder-content-our-packages-with-image custom-container" id={id}>

      <div className="service-top-section">
        <ServicesContent
          header={title}
          body={body}
        />
      </div>

      {items.length > 0 && (
        <div className="all-web-builder-our-service-images">
          {items?.filter(item => item?.product_uuid).map((props, index) => (
            <div className='our-service-item pointer' key={props.product_uuid} id={props.id}>
              <PackageCardwithImage {...props} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default OurPackagesWithImage;
