import { useState } from "react";
import { Collapse } from "reactstrap";
import { ChevronDown } from "./icons";

export default function ContentCardAccordion({
  title,
  children,
  initialOpen = false,
}) {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="content-card-collapsable">
      <button className="content-card-collapsable__button" onClick={toggle}>
        <span className="content-card-collapsable__title">{title}</span>
        <span className={`content-card-collapsable__icon ${isOpen ? "open" : ""}`}>
          <ChevronDown />
        </span>
      </button>
      <Collapse isOpen={isOpen}>
        <div className="mt-4">{children}</div>
      </Collapse>
    </div>
  );
}
