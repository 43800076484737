import { useSBSState } from "context/global";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function OtherCardTheme10({ item }) {
  const { locale } = useSBSState();

  return (
    <Link to={`/others-service/${item?.product_uuid}`}>
      <div className="home-page-other-card">
        <div className="home-page-other-card__image">
          <img src={item?.image} alt="Other Service" />
        </div>

        <div className="home-page-other-card__content">
          <h3 className="home-page-other-card__title mb-2">{item?.name_localized?.[locale] || item?.name_localized.en}</h3>
          <hr className="home-page-other-card__divider" />
          <p className="home-page-other-card__description mb-2">
            {item?.description?.[locale] || item?.description.en}
          </p>
          <div className="home-page-other-card__footer"></div>
        </div>
      </div>
    </Link>
  );
}
