import { useState } from "react";
import TransferCarousel from "./Carousel";
import TransferCardTheme2 from "./TransferCardTheme2";

export default function TransferTheme2View({ details }) {
  const { items, title, body } = details;

  // the carousel is having a clickable items
  // so we need to prevent clicking while sliding
  const [isSliding, setIsSliding] = useState(false);

  return (
    <div className="home-page-transfer-section">
      <div className="web-builder-content custom-container">
        <div className="mb-4">
          <h2 className="home-page-transfer-section__title">{title}</h2>
          <p className="home-page-transfer-section__description">{body}</p>
        </div>
        <div className="services-carousel-container">
          <TransferCarousel
            items={items}
            Component={TransferCardTheme2}
            beforeChange={() => setIsSliding(true)}
            afterChange={() => setIsSliding(false)}
            isSliding={isSliding}
          />
        </div>
      </div>
    </div>
  );
}
